import {Modal} from 'antd-mobile'
import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import api from '../../api/api'
import RoutePath from '../../config/RoutePath'

class OkOrNg extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      okNumber: props.okNumber,
      ngNumber: props.ngNumber,
      likeStatus: props.likeStatus,
      likeClassName: '',
      isChange:false,
    }
  }

  addNewsOKNg = (okOrNg) => {
    const newsId = this.props.okId
    api.news.addNewsOKNg({
      id: newsId,
      status: okOrNg,
    })
  }
  addCommentOKNg = (okOrNg) => {
    const commentId = this.props.okId
    api.news.addCommentOKNg({
      id: commentId,
      status: okOrNg,
    })
  }
  like = () => {
    if (this.props.userInfo === null) {
      Modal.alert('没有登录', '只有登录用户才能点赞评论，登录?', [
        {
          text: '去登录',
          onPress: () => this.props.history.push(RoutePath.Login),
        },
        {text: '取消', onPress: () => {}},
      ])
      return
    }

    if (this.state.likeStatus === 0) {
      return
    }

    this.setState({likeClassName: 'likeAnimation'})

    let ngNumber = this.state.ngNumber - 1
    if (this.state.likeStatus === null) {
      ngNumber = this.state.ngNumber
    }

    const okType = this.props.okType
    this.setState({
      okNumber: this.state.okNumber + 1,
      ngNumber: ngNumber,
      likeStatus: 0,
      isChange:true,
    })

    if(this.props.onChange)
    {
      this.props.onChange( this.state.okNumber + 1,ngNumber,0);
    }

    okType === '0' ? this.addNewsOKNg(0) : this.addCommentOKNg(0)
  }

  dislike = () => {
    if (this.props.userInfo === null) {
      Modal.alert('没有登录', '只有登录用户才能点赞评论，登录?', [
        {
          text: '去登录',
          onPress: () => this.props.history.push(RoutePath.Login),
        },
        {text: '取消', onPress: () => {}},
      ])
      return
    }

    if (this.state.likeStatus === 1) {
      return
    }

    this.setState({likeClassName: 'likeAnimation'})

    let okNumber = this.state.okNumber - 1
    if (this.state.likeStatus === null) {
      okNumber = this.state.okNumber
    }

    const okType = this.props.okType
    this.setState({
      okNumber: okNumber,
      ngNumber: this.state.ngNumber + 1,
      likeStatus: 1,
      isChange:true,
    })

    if(this.props.onChange)
    {
      this.props.onChange(okNumber + 1,this.state.ngNumber + 1,1);
    }

    okType === '0' ? this.addNewsOKNg(1) : this.addCommentOKNg(1)
  }

  componentWillReceiveProps(props) {

    if(this.state.isChange)
    {
      return;
    }

    if (!(this.state.likeStatus === props.likeStatus)) {
      this.setState({likeStatus: props.likeStatus})
    }

    if (!(this.state.okNumber === props.okNumber)) {
      this.setState({okNumber: props.okNumber})
    }

    if (!(this.state.ngNumber === props.ngNumber)) {
      this.setState({ngNumber: props.ngNumber})
    }
  }

  render() {
    const likeStatus = this.state.likeStatus
    return (
      <>
        <span onClick={this.like}>
          {likeStatus === 0 ? (
            <img
              className={this.state.likeClassName}
              alt=""
              style={{width: 'auto', height: 'auto'}}
              src={require('../../assets/images/like02.png')}
            />
          ) : (
            <img

              alt=""
              style={{width: 'auto', height: 'auto'}}
              src={require('../../assets/images/like.png')}
            />
          )}
          <span style={{paddingLeft: 2, cursor: 'auto'}}>
            {this.state.okNumber}
          </span>
        </span>
        <span onClick={this.dislike} style={{marginLeft: 15}}>
          {likeStatus === 1 ? (
            <img
              className={this.state.likeClassName}
              alt=""
              style={{width: 'auto', height: 'auto'}}
              src={require('../../assets/images/dislike02.png')}
            />
          ) : (
            <img

              alt=""
              style={{width: 'auto', height: 'auto'}}
              src={require('../../assets/images/dislike.png')}
            />
          )}
          <span style={{paddingLeft: 2, cursor: 'auto'}}>
            {this.state.ngNumber}
          </span>
        </span>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.myUser.userInfo,
  }
}

export default withRouter(connect(mapStateToProps, null)(OkOrNg))
