import React,{ Component } from "react";
import {connect } from 'react-redux';
import {Grid,Progress,Flex, SearchBar, Button, WingBlank, WhiteSpace} from "antd-mobile";
import MyLayout from "../common/MyLayout";
import '../../assets/style/common.css';
import api from "../../api/api";

class PlayRecord extends Component{

    constructor(props) {
        super(props);
        this.state = {
            videoListData:[],

            keyword:"",
            loading: false,
            isAll:false,
        };

        this.pageNum = 0;
        this.pageSize = 8;
        this.totalNum = 9999;
    }

    pageOnChange() {
        setTimeout(()=>this.featchMyVideoList(), 200);
    }

  featchMyVideoList() {
        if(this.state.videoListData.length >= this.totalNum)
        {
            this.setState({
                isAll:true,
                loading:false})
            return;
        }
        this.pageNum++;

        this.setState({loading:true})
        api.video.pageMyPlayVideoList(
            {
                "condition": {
                    keyword:this.state.keyword
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                this.setState({loading:false})
                if(state && data.status==200) {
                    this.totalNum = data.totalNum;
                    if (data.data.length != 0) {
                        let newData=[];
                        let newSplitArr =[];
                        newData = this.state.videoListData;
                        newSplitArr = newData.concat(data.data);//不改变现有的数组
                        this.setState({
                            videoListData:newSplitArr,
                        })
                    }
                }
            }
        )
    }

    componentDidMount() {
        this.featchMyVideoList();
    }

    ListSearchBar(){
        return(
            <Flex>
                <Flex.Item style={{flex:7}}>
                    <SearchBar
                        placeholder="视频关键字"
                        onSubmit={value => this.research()}
                        onChange={
                            (value)=>{
                                if(value)
                                {
                                    this.setState({keyword:value})
                                }
                                else
                                {
                                    this.setState({keyword:null})
                                }
                            }
                        }
                    />
                </Flex.Item>
            </Flex>
        );
    }

    research=()=> {
        this.setState({
            videoListData:[],
            refreshing: false,
            isAll:false,
        });
        this.pageNum = 0;
        this.pageSize = 8;
        this.totalNum = 9999;

        setTimeout(()=>this.featchMyVideoList(), 200,)
    };

    render() {
        let history = this.props.history;
        return(
            <MyLayout title="播放记录">
                 {this.ListSearchBar()}
                <Grid id="haha" data={this.state.videoListData}
                      columnNum={2}
                      square="false"
                      renderItem={dataItem => (
                          <div style={{ padding: '12px' }}
                               onClick={()=> history.push('/videoPlay'+'/'+dataItem.vid)
                               }>
                              <div>
                                  <img src={dataItem.posterPath}
                                       style={{width:document.documentElement.clientWidth*0.43,height:document.documentElement.clientHeight*0.11}}
                                       alt="" />
                              </div>
                              <div style={{ color: '#888', fontSize: '14px', marginTop: '12px' }}>
                                  <span className="oneTextOverflow">{dataItem.name}</span>

                              </div>
                              <div className="show-info">
                                  <div className="progress"><Progress percent={dataItem.percent} position="normal" /></div>
                                  <div aria-hidden="true">{dataItem.percent}%</div>
                              </div>
                          </div>
                      )}
                />
                <WhiteSpace/>
                <WingBlank>
                <Button
                        loading={this.state.loading}
                        onClick={
                            ()=>
                            {
                                this.featchMyVideoList();
                            }
                        }
                        >{this.state.isAll?
                        '~客官，没有更多了~':
                        (this.state.loading?
                        '数据加载中，请稍后':
                        '点击加载更多播放列表')
                        }
                </Button>
                </WingBlank>
                <WhiteSpace/>
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.myUser.userInfo,
    };
}

export default connect(mapStateToProps,null)(PlayRecord);

