import React,{ Component } from "react";
import {List, Button, Tag, Modal, Toast} from "antd-mobile";
import { createForm } from 'rc-form';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import Dict from "../../../config/Dict";
import majorData from "../../../config/majorData";
import ApplicateJoinClass from "./ApplicateJoinClass";

const Item = List.Item;
const Brief = Item.Brief;
const alert = Modal.alert;

class GetMyJoinedClasses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            listShow:true
        };
    }

    getMyJoinedClasses() {
        api.student.getMyJoinedClasses("",
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    onChange(data){
        if (data){
            this.setState({
                listShow: true,
            });
            this.getMyJoinedClasses();
        }
    }

    componentDidMount() {
        this.getMyJoinedClasses();
    }

    render() {
        let {data} = this.state;
        let history = this.props.history;
        const { getFieldProps,getFieldError} = this.props.form;
        return (
            <MyLayout title="我的班级列表">
                <div>
                    <Button type="ghost"
                            size="small"
                            style={{ margin: '14px'}}
                            onClick={()=>{this.setState({listShow:false})}}>
                        申请加入班级
                    </Button>
                    {this.state.listShow ?
                        <List className="my-list">
                            {
                                data.map((data, index) => {
                                    return (
                                        <Item key={index}
                                              extra={
                                                  <>
                                                      {data.classId}
                                                      <Brief>
                                                          <Tag style={{
                                                               backgroundColor:data.status === 0?"red":"green",
                                                               color:"white"
                                                               }}
                                                               onChange={
                                                                   ()=>{
                                                                       if(data.status != 1)
                                                                       {
                                                                            alert('退出班级', '真的要退出'+data.name+"?", 
                                                                                [
                                                                                    { text: '取消', 
                                                                                       onPress: () => {} 
                                                                                    },
                                                                                    { text: '确定', 
                                                                                      onPress: () => {
                                                                                          api.student.quitFromClass(
                                                                                              {classId:data.classId},
                                                                                              (state,data)=>{
                                                                                                  if(state && data.status === 200)
                                                                                                  {
                                                                                                    this.getMyJoinedClasses();
                                                                                                  }
                                                                                                  else
                                                                                                  {
                                                                                                      Toast.fail(data.msg);
                                                                                                  }
                                                                                              }
                                                                                          )
                                                                                      }
                                                                                    },
                                                                                ]
                                                                            )
                                                                       }
                                                                       else
                                                                       {
                                                                           Toast.info("无法从从已经审核通过的班级退出");
                                                                       }
                                                                   }
                                                               }>
                                                              
                                                              {Dict.getValue('student',data.status,'')}
                                                          </Tag>
                                                      </Brief>
                                                  </>
                                              }
                                              align="middle"
                                              multipleLine>
                                            {data.name}
                                            <Brief>{majorData.getValue(data.subjectId,'')}</Brief>
                                        </Item>
                                    )
                                })}
                        </List>:
                        <ApplicateJoinClass onChange={(data)=>this.onChange(data)}/>
                    }
                </div>
            </MyLayout>
        );
    }
}
export default createForm()(GetMyJoinedClasses);