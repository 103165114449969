import React from 'react'
import {Button} from 'antd-mobile'
import AddComment from './AddComment'
import OkOrNg from './OkOrNg'
import api from '../../api/api'

class SubComments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      commentCommentsData: [],
      commentsNum: 10000,
      loading: false,
      flashNumber: 1,
    }
    this.initPageSize = 10
    this.pageNum = 0
    this.pageSize = this.initPageSize
  }

  getComment = () => {
    if (this.state.commentCommentsData.length >= this.state.commentsNum) {
      this.setState({
        loading: false,
      })
      return
    }
    this.pageNum++
    this.setState({loading: true})
    api.news.pageSearchNewsComment(
      {
        condition: {
          pid: this.props.commentData.commentId,
        },
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      },
      (state, data) => {
        this.setState({loading: false})
        if (state && data.status === 200) {
          let commentCommentsData = this.state.commentCommentsData
          commentCommentsData = commentCommentsData.concat(data.data)
          this.setState({
            commentCommentsData: commentCommentsData,
            commentsNum: data.totalNum,
          })
        }
      }
    )
  }

  onUpdate = () => {
    this.setState({flashNumber: Math.random()})
  }

  research() {
    this.pageNum = 0
    this.pageSize = this.initPageSize
    this.commentsNum = 10000
    this.setState({
      commentCommentsData: [],
    })
    setTimeout(() => this.getComment(), 200,)
  }

  replyName = (name, returnName) => {
    return returnName ? `${name} 回复 ${returnName}` : name
  }

  componentDidMount() {
    this.getComment()
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextState.flashNumber !== this.state.flashNumber) {
      this.research()
    }
  }

  render() {
    const commentCommentsData = this.state.commentCommentsData
    const commentData = this.props.commentData

    let isAll = false
    if (this.state.commentCommentsData.length >= this.state.commentsNum) {
      isAll = true
    }

    return (
      <>
        <div style={{
          marginBottom: 10,
          paddingTop: 10,
          paddingLeft: 20,
          paddingBottom: 10,
          borderBottom: '1px solid #ddd',
          textAlign: 'left',
          background: 'rgba(241,243,244)',
          color: '#333'
        }}>
          <div>
            {commentData.name}
          </div>
          <div style={{marginBottom: 5}}>{commentData.content}</div>
          <OkOrNg
            style={{display: 'inlineBlock'}}
            okId={commentData.commentId}
            okNumber={commentData.oks}
            ngNumber={commentData.ngs}
            likeStatus={commentData.likeStatus}
            okType="1"
            onChange={(oks,ngs,likeStatus)=>{
              if(this.props.onChange)
              {
                this.props.onChange(oks,ngs,likeStatus);
              }
          }}
          />
          <AddComment
            commentRows="10"
            commonCommentId={commentData.commentId}
            commentType="1"
            onUpdate={this.onUpdate}
          />
        </div>
        {commentCommentsData.length > 0
          ? commentCommentsData.map((item, index) => {
            return (
              <div
                style={{
                  borderBottom: '1px solid #ddd',
                  paddingBottom: 10,
                  textAlign: 'left',
                  paddingLeft: 20,
                  color: '#333',
                }}
                key={index}
              >
                <p style={{marginBottom: 5, color: '#888'}}>
                  {this.replyName(item.name, item.returnName)}
                </p>
                <p style={{marginBottom: 5, marginTop: 0}}>
                  {item.content}
                </p>
                <OkOrNg
                  style={{display: 'inlineBlock'}}
                  okId={item.commentId}
                  okNumber={item.oks}
                  ngNumber={item.ngs}
                  likeStatus={item.likeStatus}
                  okType="1"
                />
                <AddComment
                  commentRows="10"
                  commonCommentId={item.commentId}
                  commentType="1"
                  onUpdate={this.onUpdate}
                />
              </div>

            )
          })
          : null}
        <Button
          loading={this.state.loading}
          onClick={
            () => {
              this.getComment()
            }
          }
        >{isAll ?
          '~客官，没有更多了~' :
          (this.state.loading ?
            '数据加载中，请稍后' :
            '点击加载更多数据')
        }
        </Button>
      </>
    )
  }
}

export default SubComments
