import {List, Modal, Button} from 'antd-mobile'
import React from 'react'
import api from '../../api/api'
import AddComment from './AddComment'
import OkOrNg from './OkOrNg'
import SubComments from './SubComments'
import {connect} from 'react-redux'

const Item = List.Item

class Comments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newsCommentsData: [],
      totalNum: 10000,
      showReply: false,
      loading: false,
      commentData: {},
    }
    this.initPageSize = 10
    this.pageNum = 0
    this.pageSize = this.initPageSize
    this.flag = 0
  }

  showReply = () => {
    this.setState({showReply: !this.state.showReply})
  }

  onClose = () => {
    this.setState({showReply: !this.state.showReply})
  }

  getComment = () => {
    if (this.state.newsCommentsData.length >= this.state.totalNum) {
      this.setState({
        loading: false,
      })
      return
    }
    this.pageNum++
    this.setState({loading: true})

    api.news.pageSearchNewsComment(
      {
        condition: {
          pid: this.props.commonId,
        },
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      },
      (state, data) => {
        this.setState({loading: false})
        if (state && data.status === 200) {
          let newsCommentsData = this.state.newsCommentsData
          newsCommentsData = newsCommentsData.concat(data.data)
          this.setState({
            newsCommentsData: newsCommentsData,
            totalNum: data.totalNum,
          })
        }
      }
    )
  }

  research() {
    this.pageNum = 0
    this.pageSize = this.initPageSize
    this.totalNum = 10000
    this.setState({
      newsCommentsData: [],
    })
    setTimeout(() => this.getComment(), 200,)
  }


  componentDidMount() {
    this.getComment()
  }

  componentWillReceiveProps(props) {
    if (props.X !== this.props.X) {
      this.research()
    }
  }

  render() {
    const {
      newsCommentsData,
      totalNum,
    } = this.state
    let isAll = false
    if (this.state.newsCommentsData.length >= totalNum) {
      isAll = true
    }

    let uid = null
    if (this.props.userInfo) {
      uid = this.props.userInfo.uid
    }
    return (
      <>
        <List renderHeader={`${totalNum}条评论`} className="my-list">
          {newsCommentsData.map((item, index) => {
            return (
              <Item className='comment-item' key={index} multipleLine wrap>
                <div style={{color: '#666'}}>
                  {uid === item.uid ? `${item.name}(作者)` : item.name}
                </div>
                <div style={{marginBottom: 5}}>{item.content}</div>
                <OkOrNg
                  style={{display: 'inlineBlock'}}
                  okId={item.commentId}
                  okNumber={item.oks}
                  ngNumber={item.ngs}
                  likeStatus={item.likeStatus}
                  okType="1"
                  onChange={(oks,ngs,likeStatus)=>{
                    item.oks = oks;
                    item.ngs = ngs;
                    item.likeStatus = likeStatus;
                  }}
                />
                <AddComment
                  commentRows="10"
                  commonCommentId={item.commentId}
                  commentType="1"
                />
                <a
                  style={{marginLeft: 15}}
                  onClick={() => {
                    this.showReply()
                    this.setState({commentData: item})
                  }}
                >
                  更多回复
                </a>
              </Item>
            )
          })}
          <Button
            loading={this.state.loading}
            onClick={
              () => {
                this.getComment()
              }
            }
          >{isAll ?
            '~客官，没有更多了~' :
            (this.state.loading ?
              '数据加载中，请稍后' :
              '点击加载更多数据')
          }
          </Button>
        </List>
        <Modal
          popup
          visible={this.state.showReply}
          onClose={this.onClose}
          animationType="slide-up"
          style={{height: '80%', background: 'white'}}
        >
          <SubComments
          onChange={(oks,ngs,likeStatus)=>{
            let commentData = this.state.commentData;
            if(commentData)
            {
              commentData.oks = oks;
              commentData.ngs = ngs;
              commentData.likeStatus = likeStatus;
              this.setState({commentData});
            }
          }}
          commentData={this.state.commentData}
          />
        </Modal>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.myUser.userInfo,
  }
}

export default connect(mapStateToProps, null)(Comments)
