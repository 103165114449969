import React,{ Component } from "react";
import {connect } from 'react-redux';
import {Flex,List,WhiteSpace, Button, Toast} from "antd-mobile";
import MyLayout from "../../common/MyLayout";
import '../../../assets/style/common.css';
import female from "../../../assets/images/female.png";
import male from "../../../assets/images/male.png";
import RoutePath from "../../../config/RoutePath";
import { Action } from "../../../actions/Action";
import api from "../../../api/api";

const Item = List.Item;
const Brief = Item.Brief;

class InfoClass extends Component{

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        //this.updateVideoDetail();
    }

    render() {
        let data = this.props.userInfo;
        let history = this.props.history;
        let role = 0;
        if(data)
        {
            role = data.role;
        }
        return(
            <MyLayout title="信息编辑">
                <List className="my-list">
                    <Item arrow="horizontal"
                          onClick={() => {history.push('/userInfoSetting'+"/baseInfo")}}>
                        基本信息
                    </Item>
                    <Item arrow="horizontal"
                          onClick={() => {history.push('/userInfoSetting'+"/address")}}>
                        个人住址
                    </Item>
                </List>
                {role === 0?
                <>
                <WhiteSpace size="lg" />
                <List className="my-list">
                    <Item arrow="horizontal"
                          onClick={() => {history.push('/userInfoSetting'+"/work")}}>
                        工作情况
                    </Item>
                    <Item arrow="horizontal"
                          onClick={() => {history.push('/userInfoSetting'+"/reward")}}>
                        奖惩情况
                    </Item>
                </List>
                <WhiteSpace size="lg" />
                <List className="my-list">
                    <Item arrow="horizontal"
                          onClick={() => {history.push('/userInfoSetting'+"/enroll")}}>
                        报考情况
                    </Item>
                    {data&&data.pcategory=='退伍军人'?
                        <Item arrow="horizontal"
                              onClick={() => {history.push('/userInfoSetting'+"/soldier")}}>
                            服役情况
                        </Item>:null
                    }
                </List>
                </>:null}
                
                <List className="my-list">
                    <Item arrow="horizontal"
                          onClick={() => {history.push(RoutePath.RealNameAuth)}}>
                        实名认证
                    </Item>
                </List>
                <WhiteSpace size="lg" />
                        <Button 
                        type='warning' 
                        onClick={
                            ()=>{
                                api.user.logout(null,(state,data)=>{

                                    if(state && data.status === 200)
                                    {
                                        this.props.resetUserInfo();
                                        history.push(RoutePath.UserInfo);
                                    }
                                    else
                                    {
                                        Toast.fail(data.msg);
                                    }
                                })
                            }
                        }>退出切换其他账号</Button>
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.myUser.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetUserInfo: (data) => dispatch({
            type: Action.RESET_USER_INFO,
        }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(InfoClass);

