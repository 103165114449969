

import React, { Component } from 'react';
import { ImagePicker } from 'antd-mobile';
import {authtoken} from '../api/utils/Request';

class ImageUploader extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    Upload = (files)=>{
        let formData = new FormData();
        formData.append("upfile", files[0].file);
        fetch("/api/authc/upload", {
                method: 'POST',
                headers: {
                    authtoken
                },
                body: formData,
        }).then((response) => response.json())
        .then((responseData)=> {
            if(this.props.onChange)
            {
                this.props.onChange(responseData.data);
            }
        }).catch((err)=> {
        });
    }

    imageSelectOnChange = (files, type, index) => {
        if(type === 'add')
        {
            this.Upload(files);
        }
        if(type === 'remove')
        {
            if(this.props.onChange)
            {
                this.props.onChange(null);
            }
        }
    }

    render() {

        let files = [];
        if(this.props.filePath)
        {
            files=[
                {
                    url:this.props.filePath,
                    id:111,
                }
            ];
        }

        return (
            <ImagePicker
                style={{ width:"400px"}}
                files={files}
                multiple={false}
                onChange={this.imageSelectOnChange}
                selectable={files.length === 0}
            />
        );
    }
}

export default ImageUploader;








