let AdressData =
	[
	{
		value: "21",
		label: "辽宁省"
	},{
		value: "11",
		label: "北京市"
	}, {
		value: "12",
		label: "天津市"
	}, {
		value: "13",
		label: "河北省"
	}, {
		value: "14",
		label: "山西省"
	}, {
		value: "15",
		label: "内蒙古自治区"
	}, {
		value: "22",
		label: "吉林省"
	}, {
		value: "23",
		label: "黑龙江省"
	}, {
		value: "31",
		label: "上海市"
	}, {
		value: "32",
		label: "江苏省"
	}, {
		value: "33",
		label: "浙江省"
	}, {
		value: "34",
		label: "安徽省"
	}, {
		value: "35",
		label: "福建省"
	}, {
		value: "36",
		label: "江西省"
	}, {
		value: "37",
		label: "山东省"
	}, {
		value: "41",
		label: "河南省"
	}, {
		value: "42",
		label: "湖北省"
	}, {
		value: "43",
		label: "湖南省"
	}, {
		value: "44",
		label: "广东省"
	}, {
		value: "45",
		label: "广西壮族自治区"
	}, {
		value: "46",
		label: "海南省"
	}, {
		value: "50",
		label: "重庆市"
	}, {
		value: "51",
		label: "四川省"
	}, {
		value: "52",
		label: "贵州省"
	}, {
		value: "53",
		label: "云南省"
	}, {
		value: "54",
		label: "西藏自治区"
	}, {
		value: "61",
		label: "陕西省"
	}, {
		value: "62",
		label: "甘肃省"
	}, {
		value: "63",
		label: "青海省"
	}, {
		value: "64",
		label: "宁夏回族自治区"
	}, {
		value: "65",
		label: "新疆维吾尔自治区"
	},
];

let cities = [{
		pvalue : "11",
		value : "110100000000",
		label : "北京市辖区"
	}, {
		pvalue : "11",
		value : "110200000000",
		label : "北京下辖县"
	}, {
		pvalue : "12",
		value : "120100000000",
		label : "天津市辖区"
	}, {
		pvalue : "12",
		value : "120200000000",
		label : "天津下辖县"
	}, {
		pvalue : "13",
		value : "130100000000",
		label : "石家庄市"
	}, {
		pvalue : "13",
		value : "130200000000",
		label : "唐山市"
	}, {
		pvalue : "13",
		value : "130300000000",
		label : "秦皇岛市"
	}, {
		pvalue : "13",
		value : "130400000000",
		label : "邯郸市"
	}, {
		pvalue : "13",
		value : "130500000000",
		label : "邢台市"
	}, {
		pvalue : "13",
		value : "130600000000",
		label : "保定市"
	}, {
		pvalue : "13",
		value : "130700000000",
		label : "张家口市"
	}, {
		pvalue : "13",
		value : "130800000000",
		label : "承德市"
	}, {
		pvalue : "13",
		value : "130900000000",
		label : "沧州市"
	}, {
		pvalue : "13",
		value : "131000000000",
		label : "廊坊市"
	}, {
		pvalue : "13",
		value : "131100000000",
		label : "衡水市"
	}, {
		pvalue : "13",
		value : "139000000000",
		label : "河北省直辖县级行政区划"
	}, {
		pvalue : "14",
		value : "140100000000",
		label : "太原市"
	}, {
		pvalue : "14",
		value : "140200000000",
		label : "大同市"
	}, {
		pvalue : "14",
		value : "140300000000",
		label : "阳泉市"
	}, {
		pvalue : "14",
		value : "140400000000",
		label : "长治市"
	}, {
		pvalue : "14",
		value : "140500000000",
		label : "晋城市"
	}, {
		pvalue : "14",
		value : "140600000000",
		label : "朔州市"
	}, {
		pvalue : "14",
		value : "140700000000",
		label : "晋中市"
	}, {
		pvalue : "14",
		value : "140800000000",
		label : "运城市"
	}, {
		pvalue : "14",
		value : "140900000000",
		label : "忻州市"
	}, {
		pvalue : "14",
		value : "141000000000",
		label : "临汾市"
	}, {
		pvalue : "14",
		value : "141100000000",
		label : "吕梁市"
	}, {
		pvalue : "15",
		value : "150100000000",
		label : "呼和浩特市"
	}, {
		pvalue : "15",
		value : "150200000000",
		label : "包头市"
	}, {
		pvalue : "15",
		value : "150300000000",
		label : "乌海市"
	}, {
		pvalue : "15",
		value : "150400000000",
		label : "赤峰市"
	}, {
		pvalue : "15",
		value : "150500000000",
		label : "通辽市"
	}, {
		pvalue : "15",
		value : "150600000000",
		label : "鄂尔多斯市"
	}, {
		pvalue : "15",
		value : "150700000000",
		label : "呼伦贝尔市"
	}, {
		pvalue : "15",
		value : "150800000000",
		label : "巴彦淖尔市"
	}, {
		pvalue : "15",
		value : "150900000000",
		label : "乌兰察布市"
	}, {
		pvalue : "15",
		value : "152200000000",
		label : "兴安盟"
	}, {
		pvalue : "15",
		value : "152500000000",
		label : "锡林郭勒盟"
	}, {
		pvalue : "15",
		value : "152900000000",
		label : "阿拉善盟"
	}, {
		pvalue : "21",
		value : "210100000000",
		label : "沈阳市"
	}, {
		pvalue : "21",
		value : "210200000000",
		label : "大连市"
	}, {
		pvalue : "21",
		value : "210300000000",
		label : "鞍山市"
	}, {
		pvalue : "21",
		value : "210400000000",
		label : "抚顺市"
	}, {
		pvalue : "21",
		value : "210500000000",
		label : "本溪市"
	}, {
		pvalue : "21",
		value : "210600000000",
		label : "丹东市"
	}, {
		pvalue : "21",
		value : "210700000000",
		label : "锦州市"
	}, {
		pvalue : "21",
		value : "210800000000",
		label : "营口市"
	}, {
		pvalue : "21",
		value : "210900000000",
		label : "阜新市"
	}, {
		pvalue : "21",
		value : "211000000000",
		label : "辽阳市"
	}, {
		pvalue : "21",
		value : "211100000000",
		label : "盘锦市"
	}, {
		pvalue : "21",
		value : "211200000000",
		label : "铁岭市"
	}, {
		pvalue : "21",
		value : "211300000000",
		label : "朝阳市"
	}, {
		pvalue : "21",
		value : "211400000000",
		label : "葫芦岛市"
	}, {
		pvalue : "22",
		value : "220100000000",
		label : "长春市"
	}, {
		pvalue : "22",
		value : "220200000000",
		label : "吉林市"
	}, {
		pvalue : "22",
		value : "220300000000",
		label : "四平市"
	}, {
		pvalue : "22",
		value : "220400000000",
		label : "辽源市"
	}, {
		pvalue : "22",
		value : "220500000000",
		label : "通化市"
	}, {
		pvalue : "22",
		value : "220600000000",
		label : "白山市"
	}, {
		pvalue : "22",
		value : "220700000000",
		label : "松原市"
	}, {
		pvalue : "22",
		value : "220800000000",
		label : "白城市"
	}, {
		pvalue : "22",
		value : "222400000000",
		label : "延边朝鲜族自治州"
	}, {
		pvalue : "23",
		value : "230100000000",
		label : "哈尔滨市"
	}, {
		pvalue : "23",
		value : "230200000000",
		label : "齐齐哈尔市"
	}, {
		pvalue : "23",
		value : "230300000000",
		label : "鸡西市"
	}, {
		pvalue : "23",
		value : "230400000000",
		label : "鹤岗市"
	}, {
		pvalue : "23",
		value : "230500000000",
		label : "双鸭山市"
	}, {
		pvalue : "23",
		value : "230600000000",
		label : "大庆市"
	}, {
		pvalue : "23",
		value : "230700000000",
		label : "伊春市"
	}, {
		pvalue : "23",
		value : "230800000000",
		label : "佳木斯市"
	}, {
		pvalue : "23",
		value : "230900000000",
		label : "七台河市"
	}, {
		pvalue : "23",
		value : "231000000000",
		label : "牡丹江市"
	}, {
		pvalue : "23",
		value : "231100000000",
		label : "黑河市"
	}, {
		pvalue : "23",
		value : "231200000000",
		label : "绥化市"
	}, {
		pvalue : "23",
		value : "232700000000",
		label : "大兴安岭地区"
	}, {
		pvalue : "31",
		value : "310100000000",
		label : "上海市辖区"
	}, {
		pvalue : "31",
		value : "310200000000",
		label : "上海下辖县"
	}, {
		pvalue : "32",
		value : "320100000000",
		label : "南京市"
	}, {
		pvalue : "32",
		value : "320200000000",
		label : "无锡市"
	}, {
		pvalue : "32",
		value : "320300000000",
		label : "徐州市"
	}, {
		pvalue : "32",
		value : "320400000000",
		label : "常州市"
	}, {
		pvalue : "32",
		value : "320500000000",
		label : "苏州市"
	}, {
		pvalue : "32",
		value : "320600000000",
		label : "南通市"
	}, {
		pvalue : "32",
		value : "320700000000",
		label : "连云港市"
	}, {
		pvalue : "32",
		value : "320800000000",
		label : "淮安市"
	}, {
		pvalue : "32",
		value : "320900000000",
		label : "盐城市"
	}, {
		pvalue : "32",
		value : "321000000000",
		label : "扬州市"
	}, {
		pvalue : "32",
		value : "321100000000",
		label : "镇江市"
	}, {
		pvalue : "32",
		value : "321200000000",
		label : "泰州市"
	}, {
		pvalue : "32",
		value : "321300000000",
		label : "宿迁市"
	}, {
		pvalue : "33",
		value : "330100000000",
		label : "杭州市"
	}, {
		pvalue : "33",
		value : "330200000000",
		label : "宁波市"
	}, {
		pvalue : "33",
		value : "330300000000",
		label : "温州市"
	}, {
		pvalue : "33",
		value : "330400000000",
		label : "嘉兴市"
	}, {
		pvalue : "33",
		value : "330500000000",
		label : "湖州市"
	}, {
		pvalue : "33",
		value : "330600000000",
		label : "绍兴市"
	}, {
		pvalue : "33",
		value : "330700000000",
		label : "金华市"
	}, {
		pvalue : "33",
		value : "330800000000",
		label : "衢州市"
	}, {
		pvalue : "33",
		value : "330900000000",
		label : "舟山市"
	}, {
		pvalue : "33",
		value : "331000000000",
		label : "台州市"
	}, {
		pvalue : "33",
		value : "331100000000",
		label : "丽水市"
	}, {
		pvalue : "34",
		value : "340100000000",
		label : "合肥市"
	}, {
		pvalue : "34",
		value : "340200000000",
		label : "芜湖市"
	}, {
		pvalue : "34",
		value : "340300000000",
		label : "蚌埠市"
	}, {
		pvalue : "34",
		value : "340400000000",
		label : "淮南市"
	}, {
		pvalue : "34",
		value : "340500000000",
		label : "马鞍山市"
	}, {
		pvalue : "34",
		value : "340600000000",
		label : "淮北市"
	}, {
		pvalue : "34",
		value : "340700000000",
		label : "铜陵市"
	}, {
		pvalue : "34",
		value : "340800000000",
		label : "安庆市"
	}, {
		pvalue : "34",
		value : "341000000000",
		label : "黄山市"
	}, {
		pvalue : "34",
		value : "341100000000",
		label : "滁州市"
	}, {
		pvalue : "34",
		value : "341200000000",
		label : "阜阳市"
	}, {
		pvalue : "34",
		value : "341300000000",
		label : "宿州市"
	}, {
		pvalue : "34",
		value : "341500000000",
		label : "六安市"
	}, {
		pvalue : "34",
		value : "341600000000",
		label : "亳州市"
	}, {
		pvalue : "34",
		value : "341700000000",
		label : "池州市"
	}, {
		pvalue : "34",
		value : "341800000000",
		label : "宣城市"
	}, {
		pvalue : "35",
		value : "350100000000",
		label : "福州市"
	}, {
		pvalue : "35",
		value : "350200000000",
		label : "厦门市"
	}, {
		pvalue : "35",
		value : "350300000000",
		label : "莆田市"
	}, {
		pvalue : "35",
		value : "350400000000",
		label : "三明市"
	}, {
		pvalue : "35",
		value : "350500000000",
		label : "泉州市"
	}, {
		pvalue : "35",
		value : "350600000000",
		label : "漳州市"
	}, {
		pvalue : "35",
		value : "350700000000",
		label : "南平市"
	}, {
		pvalue : "35",
		value : "350800000000",
		label : "龙岩市"
	}, {
		pvalue : "35",
		value : "350900000000",
		label : "宁德市"
	}, {
		pvalue : "36",
		value : "360100000000",
		label : "南昌市"
	}, {
		pvalue : "36",
		value : "360200000000",
		label : "景德镇市"
	}, {
		pvalue : "36",
		value : "360300000000",
		label : "萍乡市"
	}, {
		pvalue : "36",
		value : "360400000000",
		label : "九江市"
	}, {
		pvalue : "36",
		value : "360500000000",
		label : "新余市"
	}, {
		pvalue : "36",
		value : "360600000000",
		label : "鹰潭市"
	}, {
		pvalue : "36",
		value : "360700000000",
		label : "赣州市"
	}, {
		pvalue : "36",
		value : "360800000000",
		label : "吉安市"
	}, {
		pvalue : "36",
		value : "360900000000",
		label : "宜春市"
	}, {
		pvalue : "36",
		value : "361000000000",
		label : "抚州市"
	}, {
		pvalue : "36",
		value : "361100000000",
		label : "上饶市"
	}, {
		pvalue : "37",
		value : "370100000000",
		label : "济南市"
	}, {
		pvalue : "37",
		value : "370200000000",
		label : "青岛市"
	}, {
		pvalue : "37",
		value : "370300000000",
		label : "淄博市"
	}, {
		pvalue : "37",
		value : "370400000000",
		label : "枣庄市"
	}, {
		pvalue : "37",
		value : "370500000000",
		label : "东营市"
	}, {
		pvalue : "37",
		value : "370600000000",
		label : "烟台市"
	}, {
		pvalue : "37",
		value : "370700000000",
		label : "潍坊市"
	}, {
		pvalue : "37",
		value : "370800000000",
		label : "济宁市"
	}, {
		pvalue : "37",
		value : "370900000000",
		label : "泰安市"
	}, {
		pvalue : "37",
		value : "371000000000",
		label : "威海市"
	}, {
		pvalue : "37",
		value : "371100000000",
		label : "日照市"
	}, {
		pvalue : "37",
		value : "371200000000",
		label : "莱芜市"
	}, {
		pvalue : "37",
		value : "371300000000",
		label : "临沂市"
	}, {
		pvalue : "37",
		value : "371400000000",
		label : "德州市"
	}, {
		pvalue : "37",
		value : "371500000000",
		label : "聊城市"
	}, {
		pvalue : "37",
		value : "371600000000",
		label : "滨州市"
	}, {
		pvalue : "37",
		value : "371700000000",
		label : "菏泽市"
	}, {
		pvalue : "41",
		value : "410100000000",
		label : "郑州市"
	}, {
		pvalue : "41",
		value : "410200000000",
		label : "开封市"
	}, {
		pvalue : "41",
		value : "410300000000",
		label : "洛阳市"
	}, {
		pvalue : "41",
		value : "410400000000",
		label : "平顶山市"
	}, {
		pvalue : "41",
		value : "410500000000",
		label : "安阳市"
	}, {
		pvalue : "41",
		value : "410600000000",
		label : "鹤壁市"
	}, {
		pvalue : "41",
		value : "410700000000",
		label : "新乡市"
	}, {
		pvalue : "41",
		value : "410800000000",
		label : "焦作市"
	}, {
		pvalue : "41",
		value : "410900000000",
		label : "濮阳市"
	}, {
		pvalue : "41",
		value : "411000000000",
		label : "许昌市"
	}, {
		pvalue : "41",
		value : "411100000000",
		label : "漯河市"
	}, {
		pvalue : "41",
		value : "411200000000",
		label : "三门峡市"
	}, {
		pvalue : "41",
		value : "411300000000",
		label : "南阳市"
	}, {
		pvalue : "41",
		value : "411400000000",
		label : "商丘市"
	}, {
		pvalue : "41",
		value : "411500000000",
		label : "信阳市"
	}, {
		pvalue : "41",
		value : "411600000000",
		label : "周口市"
	}, {
		pvalue : "41",
		value : "411700000000",
		label : "驻马店市"
	}, {
		pvalue : "41",
		value : "419000000000",
		label : "河南省直辖县级行政区划"
	}, {
		pvalue : "42",
		value : "420100000000",
		label : "武汉市"
	}, {
		pvalue : "42",
		value : "420200000000",
		label : "黄石市"
	}, {
		pvalue : "42",
		value : "420300000000",
		label : "十堰市"
	}, {
		pvalue : "42",
		value : "420500000000",
		label : "宜昌市"
	}, {
		pvalue : "42",
		value : "420600000000",
		label : "襄阳市"
	}, {
		pvalue : "42",
		value : "420700000000",
		label : "鄂州市"
	}, {
		pvalue : "42",
		value : "420800000000",
		label : "荆门市"
	}, {
		pvalue : "42",
		value : "420900000000",
		label : "孝感市"
	}, {
		pvalue : "42",
		value : "421000000000",
		label : "荆州市"
	}, {
		pvalue : "42",
		value : "421100000000",
		label : "黄冈市"
	}, {
		pvalue : "42",
		value : "421200000000",
		label : "咸宁市"
	}, {
		pvalue : "42",
		value : "421300000000",
		label : "随州市"
	}, {
		pvalue : "42",
		value : "422800000000",
		label : "恩施土家族苗族自治州"
	}, {
		pvalue : "42",
		value : "429000000000",
		label : "湖北省直辖县级行政区划"
	}, {
		pvalue : "43",
		value : "430100000000",
		label : "长沙市"
	}, {
		pvalue : "43",
		value : "430200000000",
		label : "株洲市"
	}, {
		pvalue : "43",
		value : "430300000000",
		label : "湘潭市"
	}, {
		pvalue : "43",
		value : "430400000000",
		label : "衡阳市"
	}, {
		pvalue : "43",
		value : "430500000000",
		label : "邵阳市"
	}, {
		pvalue : "43",
		value : "430600000000",
		label : "岳阳市"
	}, {
		pvalue : "43",
		value : "430700000000",
		label : "常德市"
	}, {
		pvalue : "43",
		value : "430800000000",
		label : "张家界市"
	}, {
		pvalue : "43",
		value : "430900000000",
		label : "益阳市"
	}, {
		pvalue : "43",
		value : "431000000000",
		label : "郴州市"
	}, {
		pvalue : "43",
		value : "431100000000",
		label : "永州市"
	}, {
		pvalue : "43",
		value : "431200000000",
		label : "怀化市"
	}, {
		pvalue : "43",
		value : "431300000000",
		label : "娄底市"
	}, {
		pvalue : "43",
		value : "433100000000",
		label : "湘西土家族苗族自治州"
	}, {
		pvalue : "44",
		value : "440100000000",
		label : "广州市"
	}, {
		pvalue : "44",
		value : "440200000000",
		label : "韶关市"
	}, {
		pvalue : "44",
		value : "440300000000",
		label : "深圳市"
	}, {
		pvalue : "44",
		value : "440400000000",
		label : "珠海市"
	}, {
		pvalue : "44",
		value : "440500000000",
		label : "汕头市"
	}, {
		pvalue : "44",
		value : "440600000000",
		label : "佛山市"
	}, {
		pvalue : "44",
		value : "440700000000",
		label : "江门市"
	}, {
		pvalue : "44",
		value : "440800000000",
		label : "湛江市"
	}, {
		pvalue : "44",
		value : "440900000000",
		label : "茂名市"
	}, {
		pvalue : "44",
		value : "441200000000",
		label : "肇庆市"
	}, {
		pvalue : "44",
		value : "441300000000",
		label : "惠州市"
	}, {
		pvalue : "44",
		value : "441400000000",
		label : "梅州市"
	}, {
		pvalue : "44",
		value : "441500000000",
		label : "汕尾市"
	}, {
		pvalue : "44",
		value : "441600000000",
		label : "河源市"
	}, {
		pvalue : "44",
		value : "441700000000",
		label : "阳江市"
	}, {
		pvalue : "44",
		value : "441800000000",
		label : "清远市"
	}, {
		pvalue : "44",
		value : "441900000000",
		label : "东莞市"
	}, {
		pvalue : "44",
		value : "442000000000",
		label : "中山市"
	}, {
		pvalue : "44",
		value : "445100000000",
		label : "潮州市"
	}, {
		pvalue : "44",
		value : "445200000000",
		label : "揭阳市"
	}, {
		pvalue : "44",
		value : "445300000000",
		label : "云浮市"
	}, {
		pvalue : "45",
		value : "450100000000",
		label : "南宁市"
	}, {
		pvalue : "45",
		value : "450200000000",
		label : "柳州市"
	}, {
		pvalue : "45",
		value : "450300000000",
		label : "桂林市"
	}, {
		pvalue : "45",
		value : "450400000000",
		label : "梧州市"
	}, {
		pvalue : "45",
		value : "450500000000",
		label : "北海市"
	}, {
		pvalue : "45",
		value : "450600000000",
		label : "防城港市"
	}, {
		pvalue : "45",
		value : "450700000000",
		label : "钦州市"
	}, {
		pvalue : "45",
		value : "450800000000",
		label : "贵港市"
	}, {
		pvalue : "45",
		value : "450900000000",
		label : "玉林市"
	}, {
		pvalue : "45",
		value : "451000000000",
		label : "百色市"
	}, {
		pvalue : "45",
		value : "451100000000",
		label : "贺州市"
	}, {
		pvalue : "45",
		value : "451200000000",
		label : "河池市"
	}, {
		pvalue : "45",
		value : "451300000000",
		label : "来宾市"
	}, {
		pvalue : "45",
		value : "451400000000",
		label : "崇左市"
	}, {
		pvalue : "46",
		value : "460100000000",
		label : "海口市"
	}, {
		pvalue : "46",
		value : "460200000000",
		label : "三亚市"
	}, {
		pvalue : "46",
		value : "460300000000",
		label : "三沙市"
	}, {
		pvalue : "46",
		value : "469000000000",
		label : "海南省直辖县级行政区划"
	}, {
		pvalue : "50",
		value : "500100000000",
		label : "重庆市辖区"
	}, {
		pvalue : "50",
		value : "500200000000",
		label : "重庆下辖县"
	}, {
		pvalue : "51",
		value : "510100000000",
		label : "成都市"
	}, {
		pvalue : "51",
		value : "510300000000",
		label : "自贡市"
	}, {
		pvalue : "51",
		value : "510400000000",
		label : "攀枝花市"
	}, {
		pvalue : "51",
		value : "510500000000",
		label : "泸州市"
	}, {
		pvalue : "51",
		value : "510600000000",
		label : "德阳市"
	}, {
		pvalue : "51",
		value : "510700000000",
		label : "绵阳市"
	}, {
		pvalue : "51",
		value : "510800000000",
		label : "广元市"
	}, {
		pvalue : "51",
		value : "510900000000",
		label : "遂宁市"
	}, {
		pvalue : "51",
		value : "511000000000",
		label : "内江市"
	}, {
		pvalue : "51",
		value : "511100000000",
		label : "乐山市"
	}, {
		pvalue : "51",
		value : "511300000000",
		label : "南充市"
	}, {
		pvalue : "51",
		value : "511400000000",
		label : "眉山市"
	}, {
		pvalue : "51",
		value : "511500000000",
		label : "宜宾市"
	}, {
		pvalue : "51",
		value : "511600000000",
		label : "广安市"
	}, {
		pvalue : "51",
		value : "511700000000",
		label : "达州市"
	}, {
		pvalue : "51",
		value : "511800000000",
		label : "雅安市"
	}, {
		pvalue : "51",
		value : "511900000000",
		label : "巴中市"
	}, {
		pvalue : "51",
		value : "512000000000",
		label : "资阳市"
	}, {
		pvalue : "51",
		value : "513200000000",
		label : "阿坝藏族羌族自治州"
	}, {
		pvalue : "51",
		value : "513300000000",
		label : "甘孜藏族自治州"
	}, {
		pvalue : "51",
		value : "513400000000",
		label : "凉山彝族自治州"
	}, {
		pvalue : "52",
		value : "520100000000",
		label : "贵阳市"
	}, {
		pvalue : "52",
		value : "520200000000",
		label : "六盘水市"
	}, {
		pvalue : "52",
		value : "520300000000",
		label : "遵义市"
	}, {
		pvalue : "52",
		value : "520400000000",
		label : "安顺市"
	}, {
		pvalue : "52",
		value : "520500000000",
		label : "毕节市"
	}, {
		pvalue : "52",
		value : "520600000000",
		label : "铜仁市"
	}, {
		pvalue : "52",
		value : "522300000000",
		label : "黔西南布依族苗族自治州"
	}, {
		pvalue : "52",
		value : "522600000000",
		label : "黔东南苗族侗族自治州"
	}, {
		pvalue : "52",
		value : "522700000000",
		label : "黔南布依族苗族自治州"
	}, {
		pvalue : "53",
		value : "530100000000",
		label : "昆明市"
	}, {
		pvalue : "53",
		value : "530300000000",
		label : "曲靖市"
	}, {
		pvalue : "53",
		value : "530400000000",
		label : "玉溪市"
	}, {
		pvalue : "53",
		value : "530500000000",
		label : "保山市"
	}, {
		pvalue : "53",
		value : "530600000000",
		label : "昭通市"
	}, {
		pvalue : "53",
		value : "530700000000",
		label : "丽江市"
	}, {
		pvalue : "53",
		value : "530800000000",
		label : "普洱市"
	}, {
		pvalue : "53",
		value : "530900000000",
		label : "临沧市"
	}, {
		pvalue : "53",
		value : "532300000000",
		label : "楚雄彝族自治州"
	}, {
		pvalue : "53",
		value : "532500000000",
		label : "红河哈尼族彝族自治州"
	}, {
		pvalue : "53",
		value : "532600000000",
		label : "文山壮族苗族自治州"
	}, {
		pvalue : "53",
		value : "532800000000",
		label : "西双版纳傣族自治州"
	}, {
		pvalue : "53",
		value : "532900000000",
		label : "大理白族自治州"
	}, {
		pvalue : "53",
		value : "533100000000",
		label : "德宏傣族景颇族自治州"
	}, {
		pvalue : "53",
		value : "533300000000",
		label : "怒江傈僳族自治州"
	}, {
		pvalue : "53",
		value : "533400000000",
		label : "迪庆藏族自治州"
	}, {
		pvalue : "54",
		value : "540100000000",
		label : "拉萨市"
	}, {
		pvalue : "54",
		value : "540200000000",
		label : "日喀则市"
	}, {
		pvalue : "54",
		value : "540300000000",
		label : "昌都市"
	}, {
		pvalue : "54",
		value : "540400000000",
		label : "林芝市"
	}, {
		pvalue : "54",
		value : "542200000000",
		label : "山南地区"
	}, {
		pvalue : "54",
		value : "542400000000",
		label : "那曲地区"
	}, {
		pvalue : "54",
		value : "542500000000",
		label : "阿里地区"
	}, {
		pvalue : "61",
		value : "610100000000",
		label : "西安市"
	}, {
		pvalue : "61",
		value : "610200000000",
		label : "铜川市"
	}, {
		pvalue : "61",
		value : "610300000000",
		label : "宝鸡市"
	}, {
		pvalue : "61",
		value : "610400000000",
		label : "咸阳市"
	}, {
		pvalue : "61",
		value : "610500000000",
		label : "渭南市"
	}, {
		pvalue : "61",
		value : "610600000000",
		label : "延安市"
	}, {
		pvalue : "61",
		value : "610700000000",
		label : "汉中市"
	}, {
		pvalue : "61",
		value : "610800000000",
		label : "榆林市"
	}, {
		pvalue : "61",
		value : "610900000000",
		label : "安康市"
	}, {
		pvalue : "61",
		value : "611000000000",
		label : "商洛市"
	}, {
		pvalue : "62",
		value : "620100000000",
		label : "兰州市"
	}, {
		pvalue : "62",
		value : "620200000000",
		label : "嘉峪关市"
	}, {
		pvalue : "62",
		value : "620300000000",
		label : "金昌市"
	}, {
		pvalue : "62",
		value : "620400000000",
		label : "白银市"
	}, {
		pvalue : "62",
		value : "620500000000",
		label : "天水市"
	}, {
		pvalue : "62",
		value : "620600000000",
		label : "武威市"
	}, {
		pvalue : "62",
		value : "620700000000",
		label : "张掖市"
	}, {
		pvalue : "62",
		value : "620800000000",
		label : "平凉市"
	}, {
		pvalue : "62",
		value : "620900000000",
		label : "酒泉市"
	}, {
		pvalue : "62",
		value : "621000000000",
		label : "庆阳市"
	}, {
		pvalue : "62",
		value : "621100000000",
		label : "定西市"
	}, {
		pvalue : "62",
		value : "621200000000",
		label : "陇南市"
	}, {
		pvalue : "62",
		value : "622900000000",
		label : "临夏回族自治州"
	}, {
		pvalue : "62",
		value : "623000000000",
		label : "甘南藏族自治州"
	}, {
		pvalue : "63",
		value : "630100000000",
		label : "西宁市"
	}, {
		pvalue : "63",
		value : "630200000000",
		label : "海东市"
	}, {
		pvalue : "63",
		value : "632200000000",
		label : "海北藏族自治州"
	}, {
		pvalue : "63",
		value : "632300000000",
		label : "黄南藏族自治州"
	}, {
		pvalue : "63",
		value : "632500000000",
		label : "海南藏族自治州"
	}, {
		pvalue : "63",
		value : "632600000000",
		label : "果洛藏族自治州"
	}, {
		pvalue : "63",
		value : "632700000000",
		label : "玉树藏族自治州"
	}, {
		pvalue : "63",
		value : "632800000000",
		label : "海西蒙古族藏族自治州"
	}, {
		pvalue : "64",
		value : "640100000000",
		label : "银川市"
	}, {
		pvalue : "64",
		value : "640200000000",
		label : "石嘴山市"
	}, {
		pvalue : "64",
		value : "640300000000",
		label : "吴忠市"
	}, {
		pvalue : "64",
		value : "640400000000",
		label : "固原市"
	}, {
		pvalue : "64",
		value : "640500000000",
		label : "中卫市"
	}, {
		pvalue : "65",
		value : "650100000000",
		label : "乌鲁木齐市"
	}, {
		pvalue : "65",
		value : "650200000000",
		label : "克拉玛依市"
	}, {
		pvalue : "65",
		value : "650400000000",
		label : "吐鲁番市"
	}, {
		pvalue : "65",
		value : "652200000000",
		label : "哈密地区"
	}, {
		pvalue : "65",
		value : "652300000000",
		label : "昌吉回族自治州"
	}, {
		pvalue : "65",
		value : "652700000000",
		label : "博尔塔拉蒙古自治州"
	}, {
		pvalue : "65",
		value : "652800000000",
		label : "巴音郭楞蒙古自治州"
	}, {
		pvalue : "65",
		value : "652900000000",
		label : "阿克苏地区"
	}, {
		pvalue : "65",
		value : "653000000000",
		label : "克孜勒苏柯尔克孜自治州"
	}, {
		pvalue : "65",
		value : "653100000000",
		label : "喀什地区"
	}, {
		pvalue : "65",
		value : "653200000000",
		label : "和田地区"
	}, {
		pvalue : "65",
		value : "654000000000",
		label : "伊犁哈萨克自治州"
	}, {
		pvalue : "65",
		value : "654200000000",
		label : "塔城地区"
	}, {
		pvalue : "65",
		value : "654300000000",
		label : "阿勒泰地区"
	}, {
		pvalue : "65",
		value : "659000000000",
		label : "新疆自治区直辖县级行政区划"
	},
];

for(let i = 0; i < AdressData.length; i++)
{
	AdressData[i].children = [];
	for( let j = 0; j < cities.length; j++)
	{
		if(AdressData[i].value == cities[j].pvalue)
		{
			AdressData[i].children.push(cities[j]);
		}
	}
}

export default AdressData;

