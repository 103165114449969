import React, {Component} from 'react'
import MyLayout from '../common/MyLayout'
import {WhiteSpace} from 'antd-mobile'
import api from '../../api/api'
import ComFunction from '../../api/ComFunction'
import {connect} from 'react-redux'
import OkOrNg from './OkOrNg'
import AddComment from './AddComment'
import Comments from './Comments'

class NewsDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newsData: {},
      submitting: false,
      X: '',
    }
  }

  onUpdate = () => {
    this.setState({X: Math.random})
  }

  getNewsId() {
    return this.props.match.params.newsId
  }

  getNewsDetail = () => {
    api.news.getNewsDetail({newsId: this.getNewsId()}, (state, data) => {
      if (state && data.status === 200) {
        this.setState({
          newsData: data.data,
        })
      }
    })
  }

  componentDidMount() {
    this.getNewsDetail()
  }

  render() {
    const {newsData} = this.state

    return (
      <MyLayout title="资讯">
        <div style={{padding: '0 15px'}}>
          <h3 style={{textAlign: 'center'}}>{newsData.newsTitle}</h3>
          <p style={{textAlign: 'center'}}>
            <span style={{marginRight: 20}}>{newsData.newsAuthor}</span>
            <span style={{marginRight: 20}}>
              评论数&nbsp;{' '}
              {ComFunction.getVisitedTimesDisplayText(newsData.newsCommentNum)}
            </span>
            <span style={{marginRight: 20}}>
              阅读数&nbsp;{' '}
              {ComFunction.getVisitedTimesDisplayText(
                newsData.newsVisitedTimes
              )}
            </span>
            <span style={{display: 'block', marginTop: 15, marginRight: 20}}>
              {newsData.createdTime}
            </span>
          </p>
          <div
            className="newsContent"
            dangerouslySetInnerHTML={{
              __html: this.state.newsData.newsContent,
            }}
          />
          <div>
            <OkOrNg
              style={{display: 'inlineBlock'}}
              okId={newsData.newsId}
              okNumber={newsData.newsOks}
              ngNumber={newsData.newsNgs}
              likeStatus={newsData.likeStatus}
              okType="0"
            />
            <AddComment
              commentRows="10"
              commonCommentId={this.getNewsId()}
              commentType="0"
              onUpdate={this.onUpdate}
            />
          </div>
        </div>
        <WhiteSpace/>
        <Comments commonId={this.getNewsId()} X={this.state.X}/>
      </MyLayout>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.myUser.userInfo,
  }
}

export default connect(mapStateToProps, null)(NewsDetail)
