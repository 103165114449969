
import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import MyLayout from '../common/MyLayout';
import api from '../../api/api';
import { Button, WhiteSpace, Toast } from 'antd-mobile';
import RoutePath from '../../config/RoutePath';
import { connect } from 'react-redux';
import { Action } from '../../actions/Action';

class ThirdQrReader extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            code: null,
            CodeStatus:null,
        }
    }

    handleScan = data => {
        if (data) {
          if(data && data.length === 32)
          {
              api.connect.getCodeStatus(
                  {code:data},
                  (state,dt)=>{
                      if(state && dt.status === 200)
                      {
                          this.setState({CodeStatus:dt.data})
                      }
                  }
              );
          }
          this.setState({
            code: data
          })
        }
    }

    handleError = err => {
        console.error(err)
    }

    angree(){
        api.connect.userOK({
            code:this.state.code
        },
        (state,dt)=>{
            if(state && dt.status === 200)
            {
                this.props.history.push(RoutePath.Home);
            }
        }
        )
    }

    isWinxinIOS()
    {
        var UA = navigator.userAgent;
        var isAndroid = /android|adr/gi.test(UA);
        var isIOS = /iphone|ipod|ipad/gi.test(UA) && !isAndroid;
        var isWinxin = /MicroMessenger/gi.test(UA);
        if(isIOS && isWinxin)
        {
            return true;
        }
        return false;
    }

    getParam(name) {
        var search = this.props.location.search;
        if (search == "") {
            return null;
        }

        var query = search.substring(1);                                                                                                                                     
        var list = query.split('&');
        var item = [];
        for(var i = 0; i < list.length; i++) {
            item = list[i].split('=');
            if(item[0] == name) {
                return item[1];
            }
        }
        return null;
    }

    reLogin() {
        let token = this.getParam('token'); 
        if(token) {
            api.user.tokenLogin({token},
                (state,data)=>{
                if(state && data.status == 200) {
                    this.props.saveUserInfoToStore(data.data);
                } else {
                    Toast.fail(data.msg);
                }
            });
        }      
    }
    componentDidMount(){
        if(!this.props.userInfo)
        {
            this.reLogin();
        }
    }

    render() { 
        return ( 
            <MyLayout title="三方扫描">
            {
                this.isWinxinIOS()?
                <div className="iosTipsLayer">
                    <p className="tipsError">iphone用户微信暂不支持直接扫码。</p>
                    <p className="operation">只需两步操作,就能扫码：</p>
                    <p className="iosTips">1、点击右上角
                        <span>
                            <img className="tipsImg" src={require("../../assets/images/dotdot.png")}/>
                        </span>
                        <span>
                            按钮。
                        </span>
                    </p>
                    <p className="iosTips">2、选择
                        <span>
                            <img className="tipsImg" src={require("../../assets/images/safari.png")}/>
                        </span>
                        <span>
                            在Safari中打开。
                        </span>
                    </p>
                </div>
                :<>
                {
                    this.state.CodeStatus?
                    <div style={{margin:"10%"}}>
                        <div className="userTitleStyle">{this.state.CodeStatus.name}请求获取您如下信息</div>
                        <WhiteSpace/>
                        <div className="userTitleStyle">姓名，性别</div>
                        <WhiteSpace/>
                        <Button 
                        type="primary" 
                        size="small"
                        onClick={
                            ()=>{
                                this.angree();
                            }
                        }>同意</Button>
                    </div>

                    :
					<>
					<QrReader
                    delay={300}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{ width: '100%',marginTop:"50px"}}
                    legacyMode={false}
                    showViewFinder={true}
                    />
					<div>{this.state.code}</div>
					</>
                }
                </>
            }
            </MyLayout>
         );
    }
}

function mapStateToProps(state) {
    return {
      userInfo: state.myUser.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveUserInfoToStore: (data) => dispatch({
            type: Action.SAVE_USER_INFO,
            data: data,
        }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ThirdQrReader);
