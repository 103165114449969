import {Modal, List, Button, TextareaItem} from 'antd-mobile'
import React from 'react'
import api from '../../api/api'
import RoutePath from '../../config/RoutePath'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'

class AddComment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      commentValue: '',
      submitting: false,
      showReply: false,
    }
  }

  addNewsComment = (id) => {
    api.news.addNewsComment(
      {
        id,
        content: this.state.commentValue,
      },
      (state, data) => {
        if (state && data.status === 200) {
          if (this.props.onUpdate) {
            this.props.onUpdate()
          }
        }
      }
    )
  }

  addCommentComment = (id) => {
    api.news.addCommentComment(
      {
        id,
        content: this.state.commentValue,
      },
      (state, data) => {
        if (state && data.status === 200) {
          if (this.props.onUpdate) {
            this.props.onUpdate()
          }
        }
      }
    )
  }

  handleSubmit = () => {
    const commonCommentId = this.props.commonCommentId
    if (!this.state.commentValue) {
      alert('请输入评论内容')
      return
    }
    this.props.commentType === '0'
      ? this.addNewsComment(commonCommentId)
      : this.addCommentComment(commonCommentId)


    this.setState({
      submitting: true,
    })

    setTimeout(() => {
      this.setState({
        submitting: false,
        commentValue: '',
        showReply: false,
      })
    }, 1000)
  }

  handleChange = (e) => {
    this.setState({
      commentValue: e,
    })
  }

  showReply = () => {
    if (this.props.userInfo === null) {
      Modal.alert('没有登录', '只有登录用户才能点赞评论，登录?', [
        {
          text: '去登录',
          onPress: () => this.props.history.push(RoutePath.Login),
        },
        {text: '取消', onPress: () => {}},
      ])
      return
    }
    this.setState({showReply: !this.state.showReply})
  }

  onClose = () => {
    this.setState({showReply: !this.state.showReply})
  }

  render() {
    return (
      <>
        <a style={{marginLeft: 15}} onClick={this.showReply}>
          回复
        </a>
        <Modal
          popup
          visible={this.state.showReply}
          onClose={this.onClose}
          animationType="slide-up"
        >
          <List renderHeader={() => <div>添加评论</div>} className="popup-list">
            <TextareaItem
              onChange={this.handleChange}
              rows={this.props.commentRows}
              value={this.state.commentValue}
              placeholder="请添加评论"
            />
            <List.Item>
              <Button
                loading={this.state.submitting}
                onClick={this.handleSubmit}
                type="primary"
              >
                发表评论
              </Button>
            </List.Item>
          </List>
        </Modal>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.myUser.userInfo,
  }
}

export default withRouter(connect(mapStateToProps, null)(AddComment))
