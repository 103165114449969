let Action = {
    SAVE_USER_INFO:'Save_user_info_to_store',
    RESET_USER_INFO:'Reset_user_info_to_store',
    SET_LOADING_STATE:'Set_loading_state_store',
    SYS_REDAY_OK:'System_load_ready',
    REFRESH_USER_INFO:'refresh_user_info',
    SAVE_VIDEO_TYPE:'Save_Video_Types_Data',
    FEATCH_READ_NUM:'Feactch_Not_read_num_data',
    SAVE_READ_NUM:'Save_read_num_to_store',
};

export {Action};
