import React,{ Component } from "react";
import api from "../../api/api";
import {connect } from 'react-redux';
import {Icon, Carousel} from "antd-mobile";

class Animation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            imgHeight: 200,
        };
    }

    allAdvertisements() {
        api.admin.allAdvertisements({},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    handle=(link)=>{
        const w = window.open('about:blank');
        w.location.href = link;
    };

    componentDidMount() {
        this.allAdvertisements();
    }

    render(){
        const {data} = this.state;
        return(
            <>
                {
                    data.length > 0 ?
                        <Carousel autoplay={true}
                                  infinite>
                            {data.map((img,index) => (
                                <a
                                    key={index}
                                    //href="http://www.alipay.com"
                                    style={{ display: 'inline-block', width: '100%', height: this.state.imgHeight }}
                                >
                                    <img
                                        src={img.filePath}
                                        alt=""
                                        style={{ width: '100%',verticalAlign: 'top' }}
                                        onLoad={() => {
                                            // fire window resize event to change height
                                            window.dispatchEvent(new Event('resize'));
                                            this.setState({ imgHeight: 'auto' });
                                        }}
                                    />
                                </a>
                            ))}
                        </Carousel>
                        :null
                }
            </>
        )
    }
}
export default Animation;