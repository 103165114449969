import React,{ Component } from "react";
import {connect } from 'react-redux';
import {ActivityIndicator, Toast,Tabs,Grid} from "antd-mobile";
import MyLayout from "../common/MyLayout";
import api from "../../api/api";
import CON from '../../config/const';
import '../../assets/style/common.css';
import notUploaded from '../../assets/images/notUploaded.jpg';
import RoutePath from "../../config/RoutePath";

class VideoPlay extends Component{

    constructor(props) {
        super(props);
        this.state = {
            videoData:null,
            teacherScore:0,
            courseScore:0,

            rdata:[],
            isFirstPlay:0,
            activeTabPage:0,
        };
        this.lastRunPro=-1;
    }

    featchRelatives(videoId)
    {
        if(!videoId)
        {
            videoId = this.props.match.params.vid;
        }
         api.video.getRelativeVideoList({ videoId },
             (state, data) => {
                 if (state && data.status == 200) {
                     this.setState({
                         rdata: data.data,
                     });
                 }
             }
         )
    }

    updateVideoDetail(videoId) {
        if(!videoId)
        {
            videoId = this.props.match.params.vid;
        }
        api.video.videoDetail({videoId},
            (state,data)=>{
                if(state && data.status == 200) {
                    //Toast.info('成功获取视频信息，已经播放:'+data.data.percent)
                    this.setState({
                        videoData:data.data,
                    });
                }
                else
                {
                    Toast.fail(data.msg)
                }
            }
        )
    }

    onTimeupdate=(e)=>{

        let isFirstPlay = this.state.isFirstPlay;
        this.setState({
            isFirstPlay:isFirstPlay+1
        });

        let currentTime = 0;
        if(isFirstPlay == 0)
        {
            e.target.pause();
        }

        let userInfo = this.props.userInfo;
        if(!userInfo)
        {
             return;
        }

        if(isFirstPlay > 1)
        {
            let data = this.state.videoData;
            
            if (data.percent == null || data.percent==100){
                currentTime = 0;
            }else if (data.percent != null && data.percent < 100) {
                currentTime=data.percent/100*e.target.duration;
                if(currentTime != 0)
                {
                    e.target.currentTime = currentTime;
                    this.setState({isFirstPlay:-100000000})
                }

            }
        }

        let {vid} = this.props.match.params;
        let ele = e.target;
        let currentPro = parseInt(ele.currentTime/ele.duration*100)  ;

        if(currentPro >= 99)
        {
            let history = this.props.history;
            let dt = this.state.rdata[0];
            if(dt)
            {
                history.push(RoutePath.VideoPlay + '/' + dt.vid)
            }
        }

        if((currentPro%2 == 1)
         &&(this.lastRunPro != currentPro))
        {
            this.lastRunPro = currentPro;
            api.video.updatePlayPercent({
                   videoId:vid,
                   percent:currentPro
                },(state, data)=>{
                    if(state && data.status == 200) 
                    {
                        //Toast.info("提交成功");
                    }
                    else
                    {
                        Toast.fail(data.msg);
                    }
               }
            );
        }
    };

    componentDidMount() {
        this.updateVideoDetail();
        this.featchRelatives();
    }

    componentWillReceiveProps(newProps) {
        let videoId = newProps.match.params.vid;
        this.setState({isFirstPlay:0});
        this.updateVideoDetail(videoId);
        this.featchRelatives(videoId);
    }

    render() {
        const {
            videoData= null
        } = this.state;

        let history = this.props.history;
        return(
            <MyLayout title="课程学习" hideNaviBar={true}>
                <div style={{height:200,background:'black',position:'fixed',zIndex:'200'}}>
                    {videoData ?
                        <video
                            onTimeUpdate={this.onTimeupdate}
                            height="100%"
                            width="100%"
                            controls
                            src={CON.VIDEO_SERVER + videoData.filePath}
                            preload="auto"
                            webkit-playsinline
                            x5-playsinline="true" //微信自动播放会全屏播放，设置此属性 可以小窗口播放
                            playsinline="true"
                            webkit-playsinline
                            autoplay
                            controls
                        />:<div className="loading-example">
                            <div className="align">
                                <ActivityIndicator size="large" />
                                <span style={{ marginTop: 8 }}>视频加载中,请等待...</span>
                            </div>
                        </div>
                    }
                </div>
                
                <hr className="hr0"/>
                <div style={{marginTop:'205px'}}>
                <hr className="hr0"/>
                <Tabs
                    tabs={
                        [
                            {
                                title:<div>
                                    课程详情
                                </div>
                            },
                            {
                                title:<div>
                                    讲师介绍
                                </div>
                            },
                            {
                                title:<div>
                                    关联课程
                                </div>
                            }
                        ]
                    }
                    swipeable={false}
                    page={this.state.activeTabPage}
                    onTabClick={(tab,index)=>{
                        this.setState({
                            activeTabPage:index,
                        })
                    }}
                >
                    <div key='0'>
                        <div className="courseTitleStyle">{videoData!= null?videoData.name:""}</div>
                        <p className="describe">
                            {videoData!= null?videoData.intro:"无"}
                        </p>
                    </div>
                    <div key='1'>
                        <div className="course-intro" >
                            <div className="intro-img">
                                {videoData&& videoData.teacher ? <img
                                    src={videoData.teacher.photoPath}
                                />:<img src={notUploaded}/>}
                            </div>
                            <div className="intro-content">
                                <p className="intro-title">
                                    {videoData&&videoData.teacher? videoData.teacher.name:"无" }
                                </p>
                                <p className="intro-msg">
                                    {videoData&&videoData.teacher? videoData.teacher.motto:"无" }
                                </p>
                            </div>
                        </div>
                        <div className="describe">
                                    {videoData&&videoData.teacher? 
                                    <>
                                        {
                                            videoData.teacher.achievement.split("\n").map(
                                                (txt,index)=><p key={'kkk'+index}>{txt}</p>
                                            )
                                        }
                                    </>
                                    :"" }
                        </div>
                    </div>
                    <div key='2'>
                        <Grid id="haha" data={this.state.rdata}
                            columnNum={2}
                            square="false"
                            renderItem={dataItem => (
                                <div style={{ padding: '12px' }}
                                    onClick={()=> history.push(RoutePath.VideoPlay+'/'+dataItem.vid)
                                    }>
                                    <div>
                                        <img src={dataItem.posterPath}
                                            style={{width:document.documentElement.clientWidth*0.43,height:document.documentElement.clientHeight*0.11}}
                                            alt="" />
                                    </div>
                                    {/*<div>{this.ingSizeFun(dataItem)}</div>*/}
                                    <div style={{ color: '#888', fontSize: '14px', marginTop: '12px' }}>
                                        <span className="oneTextOverflow">{dataItem.name}</span>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                </Tabs>
                </div>
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.myUser.userInfo,
    };
}

export default connect(mapStateToProps,null)(VideoPlay);

