
import React, { Component } from 'react';
import Root from './Root';
import {connect } from 'react-redux';

class LoadingWapper extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <>
            {
            this.props.sysReady?
            <Root/>:
            <div>页面加载中，请稍后...</div>
           }
           </>
        );
    }
}
function mapStateToProps(state) {
    return {
      isLoading: state.myUser.isLoading,
      sysReady: state.myUser.sysReady,
    };
}
export default connect(mapStateToProps,null)(LoadingWapper);


