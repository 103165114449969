
import React, { Component } from 'react';
import {PullToRefresh, Grid, SearchBar, Flex, List,Toast, NoticeBar, Picker, Card, Progress, Button} from "antd-mobile";
import MyLayout from '../common/MyLayout';
import {connect } from 'react-redux';
import api from '../../api/api';
import RoutePath from '../../config/RoutePath';

const Item = List.Item;
const Brief = Item.Brief;

class SeriesDetail extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            data:null
         };
    }

    fetchSeriesDetail()
    {
        let seriesId = this.props.match.params.seriesId;
        api.series.getVideoSeriesDetailById(
            {seriesId},
            (state,data)=>{
                if(state && data.status == 200)
                {
                    this.setState({data:data.data})
                }
            }
        )
    }

    componentDidMount()
    {
        this.fetchSeriesDetail();
    }


    render() {
        let data = this.state.data;
        let history = this.props.history;
        let userInfo = this.props.userInfo;


        return (
            <MyLayout title="系列详情">
            {
                data?
                <div style={{backgroundColor:'white'}}>
                <List>
                    <Item>
                        <p className="userTitleStyle">{data.title}</p>
                        <img src={data.coverPath}
                            style={{ width: '100%', height: '100%' }}
                            alt="" />
                        <Brief>{data.createTime}</Brief>
                        {data.description}
                    </Item>
                </List>
                <List>

                    {
                        data.chapters.map((chapter)=>(
                            <Item key={chapter.rankIndex}
                                  multipleLine
                                  wrap>
                                <div style={{fontSize:'25'}}>第{chapter.rankIndex}章:{chapter.name}</div>
                                <List>
                                    {
                                        chapter.videos.map((video)=>(
                                            <Item key={video.rankIndex}
                                                multipleLine
                                                wrap
                                                onClick={()=> history.push(RoutePath.VideoPlay+'/'+video.videoId)}
                                                extra ={ 
                                                <img src={video.videoPosterPath}
                                                     style={{ width: '100px', height: '80px' }}
                                                     alt="" />
                                                }>
                                                <Brief>第{video.rankIndex}节:{video.videoName}</Brief>
                                                <p>完成百分比:<span style={{color:'red'}}>{video.percent}</span>%</p>
                                            </Item>
                                        ))
                                    }
                                </List>
                            </Item>
                        ))
                    }

                </List>
                </div>
                :
                null
            }
            </MyLayout>
        );
    }
}

function mapStateToProps(state) {
    return {
      userInfo: state.myUser.userInfo,
    };
}

export default connect(mapStateToProps,null)(SeriesDetail);




