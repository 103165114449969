
import React, { Component } from 'react';
import MyLayout from '../../common/MyLayout';
import api from '../../../api/api';
import { Toast, Checkbox, List, Stepper, Tabs, Badge } from 'antd-mobile';
import '../../../assets/style/common.css';
import AnswerItem from './AnswerItem';
const CheckboxItem = Checkbox.CheckboxItem;
const Item = List.Item;
const Brief = Item.Brief;

class AnswerDetail extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            paperDetail:null,
            answerDetail:null,
            questionAnswers:[],

            activeTabPage:0,
            remainSeconds:-1,
        };
    }

    divideQuestions()
    {
        let questionAnswers = [];
        questionAnswers = questionAnswers.concat(this.getTypeQuestion(5));
        for(let i = 0; i < 5; i++)
        {
            questionAnswers = questionAnswers.concat(this.getTypeQuestion(i));
        }

        this.setState({questionAnswers});
    }


    getAnswerDetailByPaperId(){
        let paperId = this.props.match.params.paperId;
        api.exam.getAnswerDetailByPaperId(
            {paperId},
            (state, data)=>{
                if(state && data.status === 200) {

                    let remainSeconds = -1;
                    if(data.data)
                    {
                        remainSeconds = data.data.remainSeconds;
                    }

                    this.setState({
                        answerDetail:data.data,
                        remainSeconds:remainSeconds,
                    });
                    setTimeout(()=>this.divideQuestions(),300);
                }else{
                    Toast.fail(data.msg);
                }
            }
        )
    }

    getPaperDetailByPaperId(){
        let paperId = this.props.match.params.paperId;
        api.exam.getPaperDetailByPaperId({paperId},
            (state, data)=>{
                if(state && data.status === 200) {
                    this.getAnswerDetailByPaperId();
                    this.setState({
                        paperDetail:data.data,
                    });
                }else{
                    Toast.fail(data.msg);
                }
            }
        )
    }

    getAnswerByQuestionId(questionId)
    {
        let answerDetail = this.state.answerDetail;
        if(answerDetail == null)
        {
            return null;
        }
        let examAnsitems = answerDetail.examAnsitems;
        for(let i = 0; i < examAnsitems.length; i++)
        {
            let ansitem = examAnsitems[i];
            if(ansitem.questionId === questionId)
            {
                return ansitem;
            }
        }
        return null;
    }

    getTypeQuestion(type)
    {
        let paperDetail = this.state.paperDetail;
        if(paperDetail == null)
        {  
            return [];
        }

        let questions = paperDetail.questions;
        let res = [];
        for (let i = 0; i < questions.length; i++) 
        {
            if(questions[i].examQuestion.itemType == type)
            {
                let dt = questions[i];
                let answer = this.getAnswerByQuestionId(dt.questionId);
                dt.answer = answer;
                res.push(dt);
            }
        }
        return res;
    }

    componentWillUnmount()
    {
        if(this.timer)
        {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    componentDidMount() {
        this.getPaperDetailByPaperId();

        if(this.timer)
        {
            clearInterval(this.timer);
            this.timer = null;
        }

        this.timer = setInterval(()=>{
            let remainSeconds = this.state.remainSeconds;
            if(remainSeconds > 0)
            {
                remainSeconds--;
                this.setState({remainSeconds});
            }
        },1000);
    }
    next()
    {
        let {questionAnswers,activeTabPage} = this.state;
        activeTabPage = activeTabPage + 1;
        if(activeTabPage < 0 || activeTabPage >= questionAnswers.length)
        {
            activeTabPage = 0;
        }
        this.setState({activeTabPage});
    }

    onAnswerSubmit=(data)=>{
        this.getAnswerDetailByPaperId();
        this.next();
    };

    render() {
        let {questionAnswers,paperDetail,answerDetail} = this.state;
        let totalAnswers = 0;
        let totalQuestions = questionAnswers.length;
        if(answerDetail)
        {
            totalAnswers = answerDetail.examAnsitems.length;
        }

        let remainSeconds = this.state.remainSeconds;
        let remainTxt = '';
        if(remainSeconds < 0)
        {
            remainTxt = '不限制时长'
        }
        else if(remainSeconds === 0)
        {
            remainTxt = '截止时间到!'
        }
        else
        {
            remainTxt = Math.floor(remainSeconds/60) + '分'  + remainSeconds%60 + '秒';
        }

        let examType = paperDetail?paperDetail.examPaper.examType:1;
        return (
            <MyLayout title="答题">
                <Item  wrap
                       multipleLine
                >
                    {paperDetail?paperDetail.examPaper.title:""}
                    <Brief>
                        <span>答题开始:</span>
                        <span  style={{color:"red"}}>{answerDetail?answerDetail.examAnswer.createTime:'--'}</span>
                    </Brief>
                    <Brief>
                        <span>倒计时:</span>
                        <span  style={{color:"red"}}>{remainTxt}</span>
                    </Brief>
                    <Brief>
                        <span style={{color:"red"}}>已答{totalAnswers}/{totalQuestions}</span>&nbsp;
                        (共{paperDetail?paperDetail.examPaper.totalScore:""}分)
                    </Brief>
                </Item>
              <Tabs
                tabs={
                    questionAnswers.map((item,index)=>{
                        return {
                            title:
                            <Badge
                             dot={item.answer == null?true:false}
                            >
                                {index+1}
                            </Badge>,
                        }
                    })
                }
                swipeable={false}
                page={this.state.activeTabPage}
                onTabClick={(tab,index)=>{
                    this.setState({
                        activeTabPage:index,
                    })
                }}
                >
                {
                    questionAnswers.map((question,indexquestion)=>
                        (
                         this.state.activeTabPage === indexquestion?
                        <AnswerItem 
                            key={indexquestion.toString()}
                            answerId={answerDetail?answerDetail.answerId:null}
                            question={question}
                            examType={examType}
                            onAnswerSubmit={this.onAnswerSubmit}
                        />:<div></div>
                    ))
                }
              </Tabs>
            </MyLayout>
        );
    }
}

export default AnswerDetail;

