import React from 'react';
import ReactDOM from 'react-dom';
import { Provider} from 'react-redux'
import { createStore} from 'redux'
import App from './App';
import ReducerRoot from './reducers/ReducerRoot'

let store = createStore(ReducerRoot);

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>, 
    document.getElementById('root')
);

export {store};
