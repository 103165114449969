
import React, { Component } from 'react';
import MyLayout from "../../common/MyLayout";
import { Action } from '../../../actions/Action';
import { connect } from 'react-redux';
import ImageUploader from '../../../components/ImageUploader';
import api from '../../../api/api';
import { Steps, Result, Button } from 'antd-mobile';
import handing from '../../../assets/images/handing.png';
import success from '../../../assets/images/success.png';

let {Step} = Steps;

class RealNameAuth extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            idcardFilepath:null,
            isChange:false,
            realNameAuth:0,
         };
    }

    editMyUserInfo()
    {
        api.user.editMyUserInfo(
            {idcardFilepath:this.state.idcardFilepath},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.props.refreshUserInfo();
                } else {
                }
            }
        )
    }

    render() {
        let userInfo = this.props.userInfo;
        return (
            <MyLayout title="实名认证">
                {userInfo?
                <div style={{margin:"5%"}}>
                <Steps 
                direction='vertical'
                current={userInfo.realNameAuth}
                >
                    <Step title="材料上传"
                          description="把身份证正反面合并到一张图上传."
                    />
                    <Step title="审核中"
                          description="审核大致需要3~5个工作日,请耐心等候."
                    />
                    <Step title="审核通过"
                           description="恭喜您，你的实名认证已通过."
                    />
                </Steps>
                    <div style={{marginTop:"5px",marginBottom:'5px'}} >

                        {
                            userInfo.realNameAuth === 0?
                            <>
                                <div style={{marginLeft:'35%'}}>
                                    <ImageUploader
                                                filePath={this.state.idcardFilepath || this.state.isChange?
                                                    this.state.idcardFilepath:userInfo.idcardFilepath}
                                                onChange={(newUrlPath)=>{
                                                    this.setState({
                                                        idcardFilepath:newUrlPath,
                                                        isChange: true,
                                                    })
                                                }}
                                            />
                                </div>
                                <div style={{marginLeft:'35%',marginBottom:"30px"}}>
                                    <span>请上传图片</span>
                                </div>
                                
                                <Button 
                                type='primary'
                                disabled={this.state.idcardFilepath == null}
                                onClick={()=>this.editMyUserInfo()}
                                >实名认证</Button>
                            </>
                            :null
                        }
                        {
                            userInfo.realNameAuth === 1?
                            <>
                                <Result
                                    img={<img src={handing} alt=""/>}
                                    title="实名认证审核中"
                                />
                            </>
                            :null
                        }

                        {
                            userInfo.realNameAuth === 2?
                            <>
                                <Result
                                    img={<img src={success} alt=""/>}
                                    title="实名认证完成"
                                />
                            </>
                            :null
                        }
                    </div>
                </div>:<div>数据加载中</div>}
            </MyLayout>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.myUser.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        refreshUserInfo: (data) => dispatch({
            type: Action.REFRESH_USER_INFO,
        }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RealNameAuth);


