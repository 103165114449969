
import React, { Component } from 'react';
import MyLayout from '../../common/MyLayout';
import {List, Button, Progress, Tag, Toast, Card} from 'antd-mobile';
import api from '../../../api/api';
import RoutePath from '../../../config/RoutePath';
import Dict from "../../../config/Dict";
const Item = List.Item;
const Brief = Item.Brief;

class MyTaskRecord extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            data:[],
         };
    }

    getMyPartinTaskList() {
        let taskType = this.props.match.params.taskType;
        api.student.getMyPartinTaskList({taskType},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.getMyPartinTaskList();
    }

    render() {
        let history = this.props.history;
        let taskType = this.props.match.params.taskType;
        return (
            <MyLayout title={taskType==0?"视频任务":"考试任务"}>
                {
                    this.state.data.length > 0?
                    <List>
                        {
                            this.state.data.map(
                                (item,index)=>(
                                    <Item key={index}
                                          multipleLine
                                          //arrow="horizontal"
                                          wrap
                                          onClick={()=>{
                                              history.push(RoutePath.TaskDetail+"/"+item.mtaskId);
                                          }}
                                    >
                                    <Card>
                                            <Card.Header
                                               title= {item.taskName}
                                               thumb={require('../../../assets/images/wheel.jpg')}
                                            />
                                            <Card.Body>
                                               <Brief>{item.taskDescription}</Brief>
                                            </Card.Body>
                                            <Card.Footer
                                                content={
                                                    <>
                                                    <Tag style={{backgroundColor:"#f4a231", color:"white"}}
                                                    small>
                                                    {item.percent}%
                                                </Tag>&nbsp;&nbsp;
                                                <Tag style={{
                                                    backgroundColor:item.taskType==0?"#2db7f5":"#87d068",
                                                    color:"white"}}
                                                    small>
                                                    {Dict.getValue('taskType',item.taskType,'')}
                                                </Tag>&nbsp;&nbsp;
                                                <Tag style={{
                                                    backgroundColor:item.freeWork==0?"#2db7f5":item.freeWork==1?"#87d068":"#f50",
                                                    color:"white"}}
                                                    small>
                                                    {Dict.getValue('freeWork',item.freeWork,'')}
                                                </Tag>
                                                </>
                                                }
                                            />
                                        </Card>
                                    </Item>
                                )
                            )
                        }
                    </List>:
                    <Button>~客官，您暂时还没收到任务~</Button>
                }
            </MyLayout>
        );
    }
}

export default MyTaskRecord;



