
import React, { Component } from 'react';
import {PullToRefresh, Grid, SearchBar, Flex, List,Toast, NoticeBar, Picker, Button} from "antd-mobile";
import MyLayout from '../common/MyLayout';
import {connect } from 'react-redux';
import api from '../../api/api';
import RoutePath from '../../config/RoutePath';
import ComFunction from '../../api/ComFunction';

const Item = List.Item;
const Brief = Item.Brief;

class SeriesPub extends Component {

    constructor(props) {
        super(props);
        this.state = {

            keyword: "",
            data:[],

            typeRecord:[null],
            loading:false,
        };


        this.initPageSize = 6;
        this.pageNum = 0;
        this.pageSize = this.initPageSize;
        this.totalNum = 10000;
    }

    fetachSeriesList(){
        if(this.state.data.length >= this.totalNum)
        {
            this.setState({
                loading:false,
            });
            return;
        }
        this.pageNum++;

        this.setState({loading:true});
        api.series.pageSerchSeries(
            {
                "condition": {
                    "typeId":this.state.typeRecord[0]?this.state.typeRecord[1]:null,
                    "keyword":this.state.keyword
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                this.setState({loading:false});
                if(state && data.status==200) {
                    this.totalNum = data.totalNum;
                    let newData = this.state.data;
                    newData = newData.concat(data.data);
                    this.setState({
                        data:newData,
                    })
                }
            }
        )
    }

    componentDidMount()
    {
        this.fetachSeriesList();
    }

    getSubArrayByTypeId(typeId)
    {
        let videoTypes = this.props.videoTypes;
        if(!videoTypes)
        {
            return [];
        }
        let res  = [];
        for(let i = 0; i <videoTypes.length; i++)
        {
            if(videoTypes[i].pid == typeId)
            {
                res.push(videoTypes[i]);
            }
        } 
        return res;
    }

    getCascadeTypeidData()
    {
        let root = this.getSubArrayByTypeId(null);
        let datas = [];
        datas.push(
            {
                value:null,
                label:'不限',
                children:[]
            }
        );
        for(let i = 0; i < root.length; i++)
        {
            var data = {
                value:root[i].typeId,
                label:root[i].name,
                children:[],
            }
            var childs = this.getSubArrayByTypeId(root[i].typeId);
            if(childs.length > 0)
            {
                let childrendata = [];
                for(let j = 0; j < childs.length; j++)
                {
                    var datain = {
                        value:childs[j].typeId,
                        label:childs[j].name,
                        children:[],
                    }
                    childrendata.push(datain);
                }
                data.children = childrendata;
                datas.push(data);
            }
        }
        return datas;
    }

    research() {
        this.pageNum = 0;
        this.pageSize = this.initPageSize;
        this.totalNum = 10000;
        this.setState({
            data:[],
        })
        setTimeout(()=>this.fetachSeriesList(), 200,);
    }

    render() {

        let history = this.props.history;
        let typeData = this.getCascadeTypeidData();
        let isAll = false;
        if(this.state.data.length >= this.totalNum)
        {
            isAll = true;
        }

        return (
            <MyLayout title="精品系列">
                <Flex>
                    <Flex.Item style={{flex:1}}>
                        <SearchBar
                            placeholder="系列关键字"
                            onChange={value=>this.setState({keyword:value})}
                            onSubmit={value => this.research()}
                        />
                    </Flex.Item>

                </Flex>

                <Flex>
                    <Flex.Item style={{flex:1}}>
                        <Picker
                         data={typeData}
                         value={this.state.typeRecord}
                         onChange={v => {
                                this.setState({ typeRecord: v });
                             }}
                         onOk={v => {
                             this.setState({ typeRecord: v })
                             this.research();
                           }}
                        >
                         <List.Item arrow="horizontal">系列选择:</List.Item>
                        </Picker>
                    </Flex.Item>
                </Flex>
                <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}>
                    通知: 重视自身健康，务必做好自我防护! 坚定信心、同舟共济、科学防治、精准施策，坚决打赢疫情防控阻击战!
                </NoticeBar>
                <p className="userTitleStyle">课程系列</p>
                <List
                    renderFooter={()=>(
                        <Button
                        loading={this.state.loading}
                        onClick={
                            ()=>
                            {
                                this.fetachSeriesList();
                            }
                        }
                        >{isAll?
                        '~客官，没有更多了~':
                        (this.state.loading?
                        '数据加载中，请稍后':
                        '点击加载更多数据')
                        }
                        </Button>
                    )}
                    >
                        {
                            this.state.data.map(
                                (item,index)=>(
                                    <Item
                                    key={index}
                                    multipleLine	
                                    onClick={()=>history.push(RoutePath.SeriesDetail + '/' + item.seriesId)
                                    }
                                    wrap
                                    >
                                     {item.title}
                                     <img src={item.coverPath}
                                       style={{ width: '100%', height: '60%' }}
                                       alt="" />
                                    <Flex justify="between" style={{marginRight:10}}>
                                            <Brief>{item.createTime}</Brief>
                                            <Brief>
                                            <img alt="" src={require("../../assets/images/play.jpg")}/>
                                            {ComFunction.getVisitedTimesDisplayText(item.visitedTimes)}
                                            </Brief>
                                    </Flex>
                                    <Brief>{item.description}</Brief>
                                    </Item>
                                )
                            )
                        }
                    </List>
            </MyLayout>
        );
    }
}


function mapStateToProps(state) {
    return {
        videoTypes: state.myUser.videoTypes,
    };
}

export default connect(mapStateToProps,null)(SeriesPub);


