import React,{ Component } from "react";
import {connect } from 'react-redux';
import {List, Button, WhiteSpace, TextareaItem, Picker, Toast, Modal, Tag, Badge} from "antd-mobile";
import { createForm } from 'rc-form';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import RoutePath from "../../../config/RoutePath";

const Item = List.Item;
const Brief = Item.Brief;
const alert = Modal.alert;

class GetMyCreatedTopicList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            topData:[],
            teachersData:[],
            listShow:true
        };
    }

    getMyCreatedTopicList() {
        api.qa.getMyCreatedTopicList("",
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        topData:data.data,
                    })
                }
            }
        )
    }

    getMyTeachers = () => {
        api.qa.getMyTeachers("",
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        teachersData: data.data
                    });
                }
            }
        )
    };

    onSubmit = e => {
        this.props.form.validateFields((err, values) => {
            //debugger
            if (!err) {
                values.tuid = values.tuid.join();
                api.qa.addTopic(
                    {...values},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.getMyCreatedTopicList();
                            alert('提示', <div>问题提交成功!</div>);
                            this.setState({listShow:true})
                        } else {
                            Toast.fail(data.msg);
                        }
                    }
                )
            }
        });
    };

    componentDidMount() {
        this.getMyCreatedTopicList();
        this.getMyTeachers();
    }

    render() {
        let qaTeacher = [];
        let {topData,teachersData} = this.state;
        let history = this.props.history;
        const { getFieldProps,getFieldError} = this.props.form;
        for (let i in teachersData) {
            qaTeacher.push({label:teachersData[i].userName, value: teachersData[i].uid});
        }
        return (
            <MyLayout title="答疑列表">
                {this.state.listShow ?
                    <div>
                        <Button type="ghost"
                                size="small"
                                style={{ margin: '14px'}}
                                onClick={()=>{this.setState({listShow:false})}}>
                            我要提问
                        </Button>
                        <List className="my-list">
                            {
                                topData.map((data, index) => {
                                    return (
                                        <Item key={index}
                                              extra={
                                                [
                                                   <Badge dot={data.status === 1}><span>{data.teacherName}</span></Badge>,
                                                   <span>&nbsp;</span>
                                                ]
                                              }
                                              align="middle"
                                              arrow="horizontal"
                                              onClick={()=> history.push(RoutePath.GetQaDetail+'/'+data.topicId)}
                                              multipleLine>
                                            {data.content}
                                            <Brief>{data.createdTime}</Brief>
                                            
                                        </Item>
                                    )
                                })}
                        </List>
                    </div>:
                    <List>
                        <TextareaItem
                            {...getFieldProps('content', {
                                rules: [
                                    {
                                        required: true,
                                        message: '问题是必须输入的!'
                                    },
                                ],
                            })}
                            placeholder="问题是必须输入的!"
                            rows={6}
                            count={1000}
                            error = {getFieldError('content')}
                        />
                        <Picker
                            data={qaTeacher}
                            cols={1}
                            {...getFieldProps("tuid",
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message:"答疑老师是必选的!"
                                        },
                                    ],
                                }
                            )}
                            error = {getFieldError('tuid')}
                        >
                            <List.Item arrow="horizontal">答疑老师</List.Item>
                        </Picker>
                        <div style={{color:"red"}}>
                            {getFieldError('tuid')}
                        </div>
                        <List.Item>
                            <Button type="ghost" onClick={this.onSubmit}>提交</Button>
                        </List.Item>
                    </List>
                }
            </MyLayout>
        );
    }
}
export default createForm()(GetMyCreatedTopicList);