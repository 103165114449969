import {SearchBar, Flex, List, NoticeBar, Button} from 'antd-mobile'
import React from 'react'
import api from '../../api/api'
import ComFunction from '../../api/ComFunction'
import RoutePath from '../../config/RoutePath'
import MyLayout from '../common/MyLayout'

const Item = List.Item
const Brief = Item.Brief

class NewsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keyword: '',
      data: [],
      typeRecord: [null],
      loading: false,
    }

    this.initPageSize = 6
    this.pageNum = 0
    this.pageSize = this.initPageSize
    this.totalNum = 10000
  }

  fetchNewsList = () => {
    if (this.state.data.length >= this.totalNum) {
      this.setState({
        loading: false,
      })
      return
    }
    this.pageNum++

    this.setState({loading: true})
    api.news.pageSearchNews(
      {
        condition: {
          keyword: this.state.keyword,
        },
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      },
      (state, data) => {
        this.setState({loading: false})
        if (state && data.status === 200) {
          this.totalNum = data.totalNum
          let newData = this.state.data
          newData = newData.concat(data.data)
          this.setState({
            data: newData,
          })
        }
      }
    )
  }

  componentDidMount() {
    this.fetchNewsList()
  }

  research = () => {
    this.pageNum = 0
    this.pageSize = this.initPageSize
    this.totalNum = 10000
    this.setState({
      data: [],
    })
    setTimeout(() => this.fetchNewsList(), 200)
  }

  render() {
    let history = this.props.history
    let isAll = false
    if (this.state.data.length >= this.totalNum) {
      isAll = true
    }

    return (
      <MyLayout>
        <Flex>
          <Flex.Item style={{flex: 1}}>
            <SearchBar
              placeholder="关键字"
              onChange={(value) => this.setState({keyword: value})}
              onSubmit={() => this.research()}
            />
          </Flex.Item>
        </Flex>
        <NoticeBar marqueeProps={{loop: true, style: {padding: '0 7.5px'}}}>
          通知: 重视自身健康，务必做好自我防护!
          坚定信心、同舟共济、科学防治、精准施策，坚决打赢疫情防控阻击战!
        </NoticeBar>
        <p className="userTitleStyle">资讯</p>
        <List
          renderFooter={() => (
            <Button
              loading={this.state.loading}
              onClick={() => {
                this.fetchNewsList()
              }}
            >
              {isAll
                ? '~客官，没有更多了~'
                : this.state.loading
                  ? '数据加载中，请稍后'
                  : '点击加载更多数据'}
            </Button>
          )}
        >
          {this.state.data.map((item, index) => (
            <Item
              key={index}
              multipleLine
              onClick={() =>
                history.push(RoutePath.NewsDetail + '/' + item.newsId)
              }
              wrap
            >
              <Flex>
                <img src={ComFunction.getScareImgeUrl(item.newsCoverpath, 100, 70)}
                     style={{width: '30%', height: 70, marginRight: 20}} alt=''/>
                <div style={{width: '90%'}}>
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '70%',
                    }}
                  >
                    {item.newsTitle}
                  </div>
                  <Brief style={{width: '70%'}}>{item.newsDesc}</Brief>
                  <Brief>
                    <span style={{marginRight: 15}}>{item.newsAuthor}</span>
                    <span style={{marginRight: 15}}>
                      <img
                        alt=""
                        style={{width: 'auto', height: 'auto'}}
                        src={require('../../assets/images/like.png')}
                      />
                      &nbsp;&nbsp;
                      {item.newsOks}
                    </span>
                    <span style={{marginRight: 15}}>
                      <img
                        alt=""
                        style={{width: 'auto', height: 'auto'}}
                        src={require('../../assets/images/dislike.png')}
                      />
                      &nbsp;&nbsp;
                      {item.newsNgs}
                    </span>
                    <span>
                      阅读数&nbsp;&nbsp;
                      {ComFunction.getVisitedTimesDisplayText(
                        item.newsVisitedTimes
                      )}
                    </span>
                  </Brief>
                </div>
              </Flex>
            </Item>
          ))}
        </List>
      </MyLayout>
    )
  }
}

export default NewsList
