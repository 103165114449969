import React,{ Component } from "react";
import {connect } from 'react-redux';
import { createForm } from 'rc-form';
import api from "../../../api/api";
import {DatePicker, InputItem, TextareaItem, List, Toast,Modal,Flex,Radio,Picker,Button} from "antd-mobile";
import MyLayout from "../../common/MyLayout";
import '../../../assets/style/common.css';
import {Action} from "../../../actions/Action";
import DataValidate from "../../../config/DataValidate";
import DatePickerTime from "../../../components/DatePickerTime";
import { dict } from '../../../config/Dict';
import AdressData from '../../../config/AdressData';
import ImageUploader from "../../../components/ImageUploader";

const Item = List.Item;
const Brief = Item.Brief;
const alert = Modal.alert;

class UserInfoSetting extends Component{

    constructor(props) {
        super(props);
        this.state = {
            idcardFilepath:null,
            isChange:false,
            sexValue: "",
            date: new Date(),
        };
    }

    resetSuccess=()=> {
        alert('提示', <div>信息修改成功</div>)
    };

    onSubmit = e => {
        let {classify} = this.props.match.params;
        this.props.form.validateFields((error, values) => {
            if (!error) {
                if(classify==="baseInfo"){
                    values.pmaxEdu = values.edu.join();
                    values.sex = values.sexDate.join();
                    values.profile = this.state.idcardFilepath;
                }
                if(classify==="enroll"){
                    values.pcategory = values.pcate.join();
                }
                if(classify==="soldier"){
                    values.pjoinJobType = values.jobType.join();
                }

                let pcity = null;
                if(values.pcity)
                {
                    pcity = values.pcity[1];
                }

                api.user.editMyUserInfo({...values,pcity}, (state,data)=>{
                        if(state && data.status == 200) {
                            this.props.saveUserInfoToStore(data.data);
                            this.resetSuccess();
                        } else {
                            Toast.fail(data.msg);
                        }
                    }
                )
            }else{
                Toast.fail("请检查信息填写的正确性!", 1);
                //Toast.fail(err.name.errors[0].message, 1);
                /**
                 arr.map((value,index,array)=>{
                })**/
            }
        });
    };

    getAdressCasValue(user)
    {
        if(user == null)
        {
            return null;
        }
        let pcity = user.pcity;
        if(pcity == null)
        {
            return null;
        }
        
        for(let i = 0; i < AdressData.length; i++)
        {
            for(let j = 0; j < AdressData[i].children.length; j++)
            {
                let cd = AdressData[i].children[j];
                if(cd.value == pcity || cd.label == pcity)
                {
                    return [cd.pvalue,cd.value];
                }
            }
        }
        return null;
    }


    render() {
        let data = this.props.userInfo;
        let {classify} = this.props.match.params;
        const { getFieldProps,getFieldError,validateFields,getFieldValue } = this.props.form;
        const pmaxEdu =[
            {label:'小学', value: '小学'},
            {label:'初中', value: '初中'},
            {label:'高中', value: '高中'},
            {label:'大学', value: '大学'},
            {label:'其它', value: '其它'}
        ];
        const pcategory =[
            {label:'农村往届', value: '农村往届'},
            {label:'农村应届', value: '农村应届'},
            {label:'城市往届', value: '城市往届'},
            {label:'城市应届', value: '城市应届'},
            {label:'农民工', value: '农民工'},
            {label:'退伍军人', value: '退伍军人'},
            {label:'下岗失业人员', value: '下岗失业人员'},
            {label:'新型职业农民', value: '新型职业农民'},
            {label:'其它', value: '其它'}
        ];
        const pjoinJobType =[
            {label:'自助择业', value: '自助择业'},
            {label:'地方安置', value: '地方安置'},
            {label:'其它', value: '其它'}
        ];
        const sex=[
            {label:'男', value: 1},
            {label:'女', value: 0}
        ];
        return(
            <MyLayout title="基本信息修改">
                <List>
                    {classify==="baseInfo"?
                        <div>
                            <div style={{marginLeft:'35%'}}>
                                <ImageUploader
                                    filePath={this.state.idcardFilepath || this.state.isChange?
                                        this.state.idcardFilepath : data?data.profile:null}
                                    onChange={(newUrlPath)=>{
                                        this.setState({
                                            idcardFilepath:newUrlPath,
                                            isChange: true,
                                        })
                                    }}
                                />
                            </div>
                            <InputItem style={{textAlign:"right"}}
                                {...getFieldProps("name",
                                    {
                                        initialValue:data?data.name:'',
                                        rules: [
                                            {
                                                required: true,
                                                message:"请输入一个新的用户名!"
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if(DataValidate.isChineseName(value) || DataValidate.isEnglishName(value)) {
                                                        callback();
                                                        return;
                                                    }
                                                    callback('error');
                                                },
                                                message: '用户名格式不对(只支持中汉字、英文字母)',
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('name')}
                                placeholder="请输入用户名"
                            >用&nbsp;户&nbsp;名</InputItem>
                            <Picker
                                data={sex}
                                cols={1}
                                extra="请选择(可选)"
                                value={data?[data.sex]:[1]}
                                format={(labels) => {return labels.join()}}
                                {...getFieldProps("sexDate",
                                    {
                                        initialValue:data?[data.sex]:'',
                                        rules: [
                                            {
                                                required: true,
                                                message:"性别必须输入!"
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('sexDate')}
                            >
                                <List.Item arrow="horizontal">性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别</List.Item>
                            </Picker>
                            <InputItem style={{textAlign:"right"}}
                                {...getFieldProps("pnature",
                                    {
                                        initialValue:data?data.pnature:'',
                                        rules: [
                                            {
                                                required: true,
                                                message:"政治面貌必须输入!"
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('pnature')}
                                placeholder="例如:党员、无党派等"
                            >政治面貌</InputItem>
                            <Picker
                                data={pmaxEdu}
                                cols={1}
                                {...getFieldProps("edu",
                                    {
                                        initialValue:data&&data.pmaxEdu?[data.pmaxEdu]:['其它'],
                                        rules: [
                                            {
                                                required: true,
                                                message:"最高学历必须输入!"
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('edu')}
                            >
                                <List.Item arrow="horizontal">最高学历</List.Item>
                            </Picker>
                        </div>:null
                    }
                    {classify==="address"?
                        <div>
                            <Picker style={{textAlign:"right"}}
                                data={AdressData}
                                {...getFieldProps("pcity",
                                    {
                                        initialValue:this.getAdressCasValue(data),
                                        rules: [
                                            {
                                                required: true,
                                                message:"所在城市必须输入!"
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('pcity')}
                                placeholder="例如:大连"
                            ><List.Item arrow="horizontal">所在城市</List.Item></Picker>


                            <TextareaItem style={{textAlign:"right"}}
                                title="详细地址"
                                placeholder="例如:大连市华北路58号"
                                autoHeight
                                {...getFieldProps("paddr",
                                    {
                                        initialValue:data?data.paddr:'',
                                        rules: [
                                            {
                                                required: true,
                                                message:"详细地址必须输入"
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('paddr')}
                            />
                        </div>:null
                    }
                    {classify==="work"?
                        <div>
                            <TextareaItem style={{textAlign:"right"}}
                                title="工作单位"
                                placeholder="例如:大连天巳集团有限公司"
                                autoHeight
                            {...getFieldProps("pworkUnit",
                                {
                                    initialValue:data?data.pworkUnit:'',
                                    rules: [
                                        {
                                            required: true,
                                            message:"工作单位必须输入"
                                        },
                                    ],
                                }
                            )}
                            error = {getFieldError('pworkUnit')}
                            />
                            <InputItem style={{textAlign:"right"}}
                                {...getFieldProps("pduty",
                                    {
                                        initialValue:data?data.pduty:'',
                                        rules: [
                                            {
                                                required: true,
                                                message:"职位必须填写"
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('pworkUnit')}
                                placeholder="例如:软件工程师，待业等">职位名称
                            </InputItem>
                            <InputItem style={{textAlign:"right"}}
                                {...getFieldProps("pworkWeekend",
                                    {
                                        initialValue:data?data.pworkWeekend:'',
                                        rules: [
                                            {
                                                required: true,
                                                message:"周末工作情况必须填写"
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('pworkWeekend')}
                                placeholder="例如:工作，不工作等">
                                周末工作
                            </InputItem>
                        </div>:null
                    }
                    {classify==="reward"?
                        <div>
                            <TextareaItem
                                title="奖惩情况"
                                placeholder="例如:2009年，荣获某某比赛1等奖"
                                autoHeight
                                {...getFieldProps("prewordPunish",
                                    {
                                        initialValue:data?data.prewordPunish:'',
                                        rules: [
                                            {
                                                required: true,
                                                message:"奖励惩罚必须输入"
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('prewordPunish')}
                            />
                        </div>:null
                    }
                    {classify==="enroll"?
                        <div>
                            <InputItem style={{textAlign:"right"}}
                                {...getFieldProps("pmajor",
                                    {
                                        initialValue:data?data.pmajor:'',
                                        rules: [
                                            {
                                                required: true,
                                                message:"报考专业必须输入"
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('pmajor')}
                                placeholder="例如:汽车电子技术"
                            >报考专业</InputItem>
                            <Picker
                                data={pcategory}
                                cols={1}
                                {...getFieldProps("pcate",
                                    {
                                        initialValue:data&&data.pcategory?[data.pcategory]:['其它'],
                                        rules: [
                                            {
                                                required: true,
                                                message:"类别是必选的!"
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('pcate')}
                            >
                                <List.Item arrow="horizontal">考生类别</List.Item>
                            </Picker>
                        </div>:null
                    }
                    {classify==="soldier"?
                        <div>
                                <DatePickerTime pickerName={"退伍时间"}
                                    {...getFieldProps("pretireTime",
                                        {
                                            initialValue:data?data.pretireTime:'',
                                            rules: [
                                                {
                                                    required: true,
                                                    message:"退伍时间必选"
                                                },
                                            ],
                                        }
                                    )}
                                />
                            <Picker
                                data={pjoinJobType}
                                cols={1}
                                {...getFieldProps("jobType",
                                    {
                                        initialValue:data&&data.pjoinJobType?[data.pjoinJobType]:['其它'],
                                        rules: [
                                            {
                                                required: true,
                                                message:"择业是必选的!"
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('jobType')}
                            >
                                <List.Item arrow="horizontal">择业方式</List.Item>
                            </Picker>
                            <TextareaItem style={{textAlign:"right"}}
                                title="服役期奖惩"
                                placeholder="例如:2009年，荣获某某比赛一等功"
                                autoHeight
                                {...getFieldProps("parmRewordPunish",
                                    {
                                        initialValue:data?data.parmRewordPunish:'',
                                        rules: [
                                            {
                                                required: true,
                                                message:"服役期间奖惩必须输入"
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('parmRewordPunish')}
                            />
                            <TextareaItem style={{textAlign:"right"}}
                                title="退役部队"
                                placeholder="例如:中国人民解放军XXXXX部队(或填保密)"
                                autoHeight
                                {...getFieldProps("pretireArm",
                                    {
                                        initialValue:data?data.pretireArm:'',
                                        rules: [
                                            {
                                                required: true,
                                                message:"退役部队情况必须填写"
                                            },
                                        ],
                                    }
                                )}
                                error = {getFieldError('pretireArm')}
                            />
                        </div>:null}
                    <List.Item>
                        <Button type="ghost" onClick={this.onSubmit}>提交</Button>
                    </List.Item>
                </List>
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.myUser.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveUserInfoToStore: (data) => dispatch({
            type: Action.SAVE_USER_INFO,
            data: data,
        }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(createForm()(UserInfoSetting));
//export default connect(mapStateToProps,null)(UserInfo);