
import React, { Component } from 'react';
import MyLayout from '../../common/MyLayout';
import {List, Button, Progress, Modal, Tag, Toast} from 'antd-mobile';
import api from '../../../api/api';
import RoutePath from '../../../config/RoutePath';
import Dict from "../../../config/Dict";
const Item = List.Item;
const Brief = Item.Brief;

class TaskDetail extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:{},
            videoList:[],
            paperList:[],
         };
    }

    getTaskDetailById() {
        let mtaskId = this.props.match.params.mtaskId;
        api.taskadmin.getTaskDetailById({mtaskId},
            (state,data)=>{
                if(state && data.status === 200) {
                    if(data.data.taskType === 0)
                    {
                        this.getMyLearnVideoListByTaskId(mtaskId);
                    }
                    else
                    {
                        this.getMyLearnPaperListByTaskId(mtaskId);
                    }

                    this.setState({
                        data:data.data,
                    });
                }
            }
        )
    }

    getMyLearnVideoListByTaskId(mtaskId){
        api.student.getMyLearnVideoListByTaskId(
            {mtaskId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        videoList:data.data,
                    })
                }
            }
        )
    }

    getMyLearnPaperListByTaskId(mtaskId){
        api.student.getMyLearnPaperListByTaskId(
            {mtaskId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        paperList:data.data,
                    })
                }
            }
        )
    }

    applicateFreeTask(mtaskId){
        api.student.applicateFreeTask({mtaskId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.getTaskDetailById();
                    Toast.success('免修申请已经提交,请等待审批...!', 2);
                }
            }
        )
    }

    revokeFreeTask(mtaskId){
        api.student.revokeFreeTask({mtaskId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.getTaskDetailById();
                    Toast.success('已成功撤销', 2);
                }
            }
        )
    }

    componentDidMount() {
        this.getTaskDetailById();
    }

    render() {
        let {data,videoList,paperList} = this.state;
        let history = this.props.history;
        let mtaskId = this.props.match.params.mtaskId;
        return (
            <MyLayout title="任务详情">
            <Item  wrap
                   multipleLine
                   extra2={[
                        <Tag style={{
                        backgroundColor:data.freeWork==0?"#2db7f5":data.freeWork==1?"#87d068":"#f50",
                        color:"white"}}
                        small>
                        {Dict.getValue('freeWork',data.freeWork,'')}
                    </Tag>,
                       <Button type="ghost"
                               size="small"
                               disabled={data.freeWork==1}
                               onClick={()=>this.applicateFreeTask(mtaskId)}>
                           申请免修
                       </Button>]
                   }
            >
                <Brief style={{color:'black'}}>{data.taskName}</Brief>

                <Brief>{data.taskDescription}</Brief>
                <Brief>任务开始：{data.startTime}</Brief>
                <Brief>任务结束：{data.endTime}</Brief>
                <Brief>
                     免修状态：
                    <Tag style={{
                        backgroundColor:data.freeWork==0?"#2db7f5":data.freeWork==1?"#87d068":"#f50",
                        color:"white"}}
                        small>
                        {Dict.getValue('freeWork',data.freeWork,'')}
                    </Tag>
                </Brief>
                {
                    data.freeWork != 2?
                    <Button type="ghost"
                               style={{marginTop:'10px'}}
                               size="small"
                               //disabled={data.freeWork==1}
                               onClick={()=>{
                                   if(data.freeWork === 0)
                                   {
                                         this.applicateFreeTask(mtaskId);
                                   }
                                   else
                                   {
                                       this.revokeFreeTask(mtaskId);
                                   }
                               } }>
                               {data.freeWork === 0 ?'申请免修':'撤销申请'}
                    </Button>:null
                }
            </Item>
            {videoList.length > 0?
                <List>
                    {
                        videoList.map(
                            (video,index)=>
                            <Item key={video.vid}
                                    multipleLine
                                    wrap
                                    onClick={()=> history.push(RoutePath.VideoPlay+'/'+video.vid)}
                                    arrow="horizontal"
                                    >
                                    <span style={{marginRight:"8px"}}>{video.name}</span>
                                    <Brief>
                                        <Tag style={{
                                            backgroundColor:"#87d068",
                                            color:"white"}}
                                             small>
                                            {video.keywords?video.keywords:"关键字 无"}
                                        </Tag>
                                        &nbsp;&nbsp;
                                        <Tag style={{
                                            backgroundColor:"#2db7f5",
                                            color:"white"}}
                                             small>
                                            {video.teacher?video.teacher.name:"匿名上传"}
                                        </Tag>
                                    </Brief>
                                    <div className="show-info">
                                        <div className="progress">
                                            <Progress percent={video.percent}
                                                      position="normal" />
                                        </div>
                                        <Tag style={{backgroundColor:"#f4a231", color:"white"}}
                                             small>
                                            <div aria-hidden="true">{video.percent}%</div>
                                        </Tag>
                                    </div>
                            </Item>
                        )
                    }
                </List>:null
            }
            {paperList.length > 0?
                <List>
                    {
                        paperList.map(
                            (paper,index)=>
                            <Item key={paper.paperId}
                                    multipleLine
                                    wrap
                                    onClick={()=>{
                                        Modal.alert('!!!重要提醒!!!', 
                                        <span style={{color:'red'}}>一旦点击开始答题，就代表考试开始，并开始计时，不可撤销，不可暂停，您准备好考试了吗?</span>, 
                                        [
                                            {
                                            text: '开始答题',
                                            onPress: () => history.push(RoutePath.AnswerDetail+'/'+paper.paperId),
                                            },
                                            {text: '再等会儿', onPress: () => {}},
                                        ])
                                    }}
                                    arrow="horizontal"
                                    >
                                        <span style={{marginRight:"8px"}}>{paper.title}</span>
                                        <Tag style={{
                                            backgroundColor:paper.examType==0?"#87d068":"#2db7f5",
                                            color:"white"}}
                                             small>
                                            {Dict.getValue('examType',paper.examType,'')}
                                        </Tag>
                                        <div>答题开始:{paper.startTime?paper.startTime:'不限'}</div>
                                        <div>答题结束:{paper.endTime?paper.endTime:'不限'}</div>
                                        <div>答题时长:{paper.duration?paper.duration+'分钟':'不限'}</div>
                                        <div className="show-info">
                                            <div className="progress">
                                                <Progress percent={paper.percent}
                                                          position="normal" />
                                            </div>
                                            <Tag style={{backgroundColor:"#f4a231", color:"white"}}
                                                 small>
                                                <div aria-hidden="true">{paper.percent}%</div>
                                            </Tag>
                                        </div>
                            </Item>
                        )
                    }
                </List>:null
            }
            </MyLayout>
        );
    }
}

export default TaskDetail;


