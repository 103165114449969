let RoutePath = {
    Home:'/home',
    Login:'/login',
    Register:'/register',
    VideoPlay:'/videoPlay',
    UserInfo:'/userInfo',
    UserInfoSetting:"/userInfoSetting",
    PlayRecord:'/playRecord',
    InfoClass:'/infoClass',
    SeriesPub:'/pub/seriespub',
    SeriesDetail:'/pub/seriesdetail',
    AnswerRecord:'/personal/answer/answerrecord',
    MyClassRecord:'/personal/myclass/myclassrecord',
    MyTaskRecord: '/personal/mytask/mytaskrecord',
    MyMessageList: '/personal/mymessage/mymessagelist',
    AnswerDetail: '/personal/answer/answerdetail',
    TaskDetail:'/personal/mytask/taskdetail',
    RealNameAuth:'/user/userinfo/realnameauth',
    GetMyCreatedTopicList:'/personal/qa/GetMyCreatedTopicList',
    GetQaDetail:'/personal/qa/GetQaDetail',
    ThirdQrReader: '/qr/thirdQrReader',
    FetchPersonalStatics: '/personal/statistic/FetchPersonalStatics',
    MyPaperRecord: '/personal/writepaper/mypaperrecord',
    NewsList: '/news/newsList',
    NewsDetail: '/news/newsDetail',
    NewsHistory: '/news/NewsHistory',
    CertificateQuery: '/CertificateQuery'
};

export default RoutePath;