import React,{ Component } from "react";
import {DatePicker,List} from "antd-mobile";
import moment  from 'moment';

const Item = List.Item;
class DatePickerTime extends Component{

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    onChange(date) {
        let timeData = moment(date).format('YYYY/MM/DD 00:00:00');
        this.props.onChange(timeData);
    }

    render() {
        let dateFormat = "YYYY/MM/DD 00:00:00";
        let value = null;
        if(this.props.value) {
            value = new Date(this.props.value)
        }
        return (
                <DatePicker size="large"
                            value={value}
                            format={dateFormat}
                            onChange={(d)=>this.onChange(d)}>
                    <List.Item>{this.props.pickerName}</List.Item>
                </DatePicker>
        );
    }
}

export default DatePickerTime;