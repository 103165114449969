
import React, { Component } from 'react';
import MyLayout from '../../common/MyLayout';
import {List, Tag, Button, Progress} from 'antd-mobile';
import api from '../../../api/api';
import RoutePath from '../../../config/RoutePath';
const Item = List.Item;

class MyPaperRecord extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:[],
         };
    }

    fetchMyPaperRecord()
    {
        api.exam.getMyCreatePaperList(
            null,
            (state,data)=>{
                if(state && data.status === 200)
                {
                    this.setState({
                        data:data.data
                    })
                }
            }
        )
    }

    componentDidMount()
    {
        this.fetchMyPaperRecord();
    }

    render() {
        let history = this.props.history;
        return (
            <MyLayout title="我出卷列表">
            <List>
                        {
                            this.state.data.map(
                                (item,index)=>(
                                    <Item
                                    key={index}
                                    multipleLine
                                    arrow="horizontal"
                                    extra={
                                        <>
                                        <div>{
                                        item.examType === 0?
                                        <span>
                                            <Tag style={{backgroundColor:"#f50", color:"white"}}
                                                 small>
                                                    作业
                                            </Tag>
                                        </span>
                                        :<span>
                                            <Tag style={{backgroundColor:"#87d068", color:"white"}}
                                                 small>
                                                    考试
                                            </Tag>
                                            </span>
                                        }</div>
                                        </>
                                    }
                                    onClick={()=>history.push(
                                        RoutePath.AnswerDetail + "/" + item.paperId
                                    )}
                                    >
                                     <>{item.title}</>
                                    </Item>
                                )
                            )
                        }
                    </List>

            </MyLayout>
        );
    }
}

export default MyPaperRecord;