import { store } from "../..";
import { Action } from "../../actions/Action";
import { Toast } from "antd-mobile";

const URLBuild = require("./URLBuild")
let authtoken = null;
if(sessionStorage)
{
    authtoken = sessionStorage.getItem('authtoken');
}
let ready = false;

function randomString(len) {
    　　len = len || 32;
    　　var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; 
    　　var maxPos = $chars.length;
    　　var pwd = '';
    　　for (var i = 0; i < len; i++) {
    　　　　pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    　　}
    　　return pwd;
}

if(authtoken == null)
{
	authtoken = randomString(32);
    if(sessionStorage)
	{
		sessionStorage.setItem('authtoken',authtoken);
	}
}

function setauthtoken(tk)
{
    if(tk == null || tk == 'null')
    {
        return;
    }

    if(!ready)
    {
        ready = true;
    }

    if(tk == authtoken)
    {
        //已经存了。
        return;
    }

    authtoken = tk;
    //Toast.info(authtoken);
    if(sessionStorage)
    {
        sessionStorage.setItem('authtoken',authtoken);
    }
}

function authtokenHandle(response) {
    let authtoken = response.headers.has('authtoken');
    if (authtoken) {
        authtoken = response.headers.get('authtoken');
        //console.log(authtoken);
        setauthtoken(authtoken);
    }
    return response;
}


function callbackFilter(state, data, cbk, param) {

    console.log("||================================");
    console.log("||\tURL:", param.url);
    console.log("||\tstatus:", state);
    console.log("||\tdata:", data);
    console.log("||\trequest:", param);
    console.log("||================================");

    if (!state || data.status == 403) {
        //console.log("login-----------------------------------")
        //wx.redirectTo({url: '/pages/index/index'});
        //store.dispatch(
        //{ 
        //        type: CON.RESET_USER_INFO,
        //})
    }
    try {
        if (cbk)
            cbk(state, data);
    } catch (e) {

        console.log("||=================ERROR===================");
        console.log("||\turl:", param.url);
        console.log("||\terr:", e);
        console.log("||=========================================");
    }
}

function GET(path) {
    return function (param, cbk) {
        var url = new URLBuild(path).addAll(param).build();

        //Toast.info(url);
        //Toast.info(authtoken);
        var rs = fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "authtoken":authtoken,
            },
            method: 'GET',
        });
        rs.then(authtokenHandle);

        rs.then((response) => response.json())
            .then(function (res, request) {
                callbackFilter(true, res, cbk, {
                    url: url,
                    data: param,
                    res: res
                });
            }).catch((error) => {
            callbackFilter(false, error, cbk, {
                url: url,
                data: param,
                res: error
            });
        });

    }
}

function POST(url) {
    return function (data, cbk) {

        //Toast.info(url);
        //Toast.info(authtoken);
        var rs = fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "authtoken":authtoken,
            },
            method: 'POST',
            body: JSON.stringify(data)
        });



        rs.then(authtokenHandle);

        rs.then((response) => response.json())
            .then(function (res, request) {
                callbackFilter(true, res, cbk, {
                    url: url,
                    data: data,
                    res: res
                });
            }).catch((error) => {
            callbackFilter(false, error, cbk, {
                url: url,
                data: data,
                res: error
            });
        });
    }
}

export {POST, GET,authtoken}
