import React,{ Component } from "react";
import {List, Button, Pagination, Icon , Toast, SearchBar} from "antd-mobile";
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import majorData from "../../../config/majorData";

const Item = List.Item;
const Brief = Item.Brief;

class ApplicateJoinClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subjectId:"",
            classId:"",
            name:"",

            data:[],
            pageNum:1,
            pageSize:5,
            totalNum:9,
        };
    }

    pageOnChange(pageNum) {
        this.setState({
            pageNum,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.searchClass(), 200);
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:5,
            totalNum:9,
        });
        setTimeout(()=>this.searchClass(), 200,);
    }

    searchClass() {
        api.class.searchClass({
                "condition": {
                    "classId": this.state.classId,
                    "name": this.state.name,
                    "orgId": "",
                    "subjectId":this.state.subjectId
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    applicateJoinClass=(classId)=>{
        api.student.applicateJoinClass({
                "classId":classId
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.props.onChange(state);//组件向父类传值
                    Toast.success('您的申请已提交,待管理员审核通过后,方可进入该班级!', 2);
                }
                else if (data.status == 6000360){
                    Toast.success('您已经申请过加入本班级，不用再申请了!', 2);
                }
            }
        )
    };

    componentDidMount() {
        this.searchClass();
    }

    render() {
        let {data} = this.state;
        let history = this.props.history;
        return (
            <MyLayout title="申请加入班级">
                <div>
                    <SearchBar
                        placeholder="请输入级名"
                        onChange={value=>this.setState({name:value})}
                        onSubmit={value => this.research()}
                    />
                    <List className="my-list">
                        {
                            data.map((data, index) => {
                                return (
                                    <Item key={index}
                                          extra={
                                              <>
                                                  {data.classId}
                                                  <Button type="ghost"
                                                          size="small"
                                                          style={{ margin: '14px'}}
                                                          onClick={()=>this.applicateJoinClass(data.classId)}>
                                                      申请加入
                                                  </Button>
                                              </>
                                          }
                                          align="middle"
                                          multipleLine>
                                        {data.name}
                                        <Brief>{majorData.getValue(data.subjectId,'')}</Brief>
                                    </Item>
                                )
                            })}
                    </List>
                </div>
                <Pagination total={Math.ceil(this.state.totalNum/this.state.pageSize)}
                            className="custom-pagination-with-icon"
                            current={this.state.pageNum}
                            onChange={(pageNum)=>this.pageOnChange(pageNum)}
                            locale={{
                                prevText: (<span className="arrow-align"><Icon type="left" />上一页</span>),
                                nextText: (<span className="arrow-align">下一页<Icon type="right" /></span>),
                            }}
                />
            </MyLayout>
        );
    }
}
export default ApplicateJoinClass