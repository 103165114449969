import React, {Component} from 'react'
import {Card, WingBlank, WhiteSpace, Flex} from 'antd-mobile'
import api from '../../../api/api'
import MyLayout from '../../common/MyLayout'
import notUploaded from '../../../assets/images/notUploaded.jpg'
import PersonalSnapshots from './PersonalSnapshots'

class FetchPersonalStatics extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  fetchPersonalStatics() {
    api.statics.fetchPersonalStatics('',
      (state, data) => {
        if (state && data.status === 200) {
          this.setState({
            data: data.data,
          })
        }
      }
    )
  }

  componentDidMount() {
    this.fetchPersonalStatics()
  }

  render() {
    let {data} = this.state
    return (
      <MyLayout title="学习信息统计">
        {data ?
          <div>
            <WhiteSpace size="lg"/>
            <div className="course-intro">
              <div className="intro-img">
                <img
                  alt=''
                  src={data.profile ? data.profile : notUploaded}
                />
              </div>
              <div className="intro-content">
                <p className="intro-msg">
                  姓名: {data.name}
                </p>
                <p className="intro-msg">
                  联系方式: {data.phone}
                </p>
                <p className="intro-msg">
                  更新时间: {data.updateTime.substring(0, 16)}
                </p>
              </div>
            </div>
            <WingBlank>
              <Flex style={{paddingBottom: '10px', color: '#12a7a7'}}>
                <Flex.Item>参与任务数</Flex.Item>
                <Flex.Item>加入班级数</Flex.Item>
                <Flex.Item>任务完成度</Flex.Item>
              </Flex>
              <Flex style={{fontSize: '22px'}}>
                <Flex.Item>{data.numTask}个</Flex.Item>
                <Flex.Item>{data.numClass}个</Flex.Item>
                <Flex.Item>{data.percentTask ? data.percentTask + '%' : '0 %'}</Flex.Item>
              </Flex>
            </WingBlank>
            <hr className="hr0"/>
            <WingBlank size="lg">
              <WhiteSpace size="lg"/>
              <Card>
                <Card.Body>
                  <Flex style={{color: '#3f8600', paddingBottom: '10px'}}>
                    <Flex.Item>累计学习视频数</Flex.Item>
                    <Flex.Item>累计学习视频时长</Flex.Item>
                  </Flex>
                  <Flex style={{fontSize: '22px'}}>
                    <Flex.Item>{data ? data.numVideo : '0'}个</Flex.Item>
                    <Flex.Item>{data.numVideoDuration ? data.numVideoDuration : '0'}分钟</Flex.Item>
                  </Flex>
                  <WhiteSpace size="lg"/>
                  <Flex style={{color: '#3f8600', paddingBottom: '10px'}}>
                    <Flex.Item>第一次学习时间</Flex.Item>
                    <Flex.Item>最后一次学习时间</Flex.Item>
                  </Flex>
                  <Flex>
                    <Flex.Item>{data.dateFirstwatch ? data.dateFirstwatch.substring(0, 16) : '--'}</Flex.Item>
                    <Flex.Item>{data.dateLastwatch ? data.dateLastwatch.substring(0, 16) : '--'}</Flex.Item>
                  </Flex>
                </Card.Body>
              </Card>
              <WhiteSpace size="lg"/>
            </WingBlank>
            <WingBlank size="lg">
              <WhiteSpace size="lg"/>
              <Card>
                <Card.Body>
                  <Flex style={{color: '#2db7f5', paddingBottom: '10px'}}>
                    <Flex.Item>累计答卷数</Flex.Item>
                    <Flex.Item>累计答题数</Flex.Item>
                  </Flex>
                  <Flex style={{fontSize: '22px'}}>
                    <Flex.Item>{data.numAnswer}套</Flex.Item>
                    <Flex.Item>{data.numAnswerItems}道</Flex.Item>
                  </Flex>
                  <WhiteSpace size="lg"/>
                  <Flex style={{color: '#2db7f5', paddingBottom: '10px'}}>
                    <Flex.Item>第一次答卷时间</Flex.Item>
                    <Flex.Item>最后一次答卷时间</Flex.Item>
                  </Flex>
                  <Flex>
                    <Flex.Item>{data.dateFirstlearn ? data.dateFirstlearn.substring(0, 16) : '--'}</Flex.Item>
                    <Flex.Item>{data.dateLastlearn ? data.dateLastlearn.substring(0, 16) : '--'}</Flex.Item>
                  </Flex>
                </Card.Body>
              </Card>
              <WhiteSpace size="lg"/>
            </WingBlank>
          </div> : ''
        }
        <div>
          <PersonalSnapshots/>
        </div>
      </MyLayout>
    )
  }
}

export default FetchPersonalStatics