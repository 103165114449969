import {Flex, List, Button} from 'antd-mobile'
import React from 'react'
import api from '../../api/api'
import RoutePath from '../../config/RoutePath'
import MyLayout from '../common/MyLayout'

const Item = List.Item
const Brief = Item.Brief

class NewsHistory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keyword: '',
      data: [],

      typeRecord: [null],
      loading: false,
    }

    this.initPageSize = 6
    this.pageNum = 0
    this.pageSize = this.initPageSize
    this.totalNum = 10000
  }

  fetchNewsList = () => {
    if (this.state.data.length >= this.totalNum) {
      this.setState({
        loading: false,
      })
      return
    }
    this.pageNum++

    this.setState({loading: true})
    api.news.pageSeachNewsHistory(
      {
        condition: {},
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      },
      (state, data) => {
        this.setState({loading: false})
        if (state && data.status === 200) {
          this.totalNum = data.totalNum
          let newData = this.state.data
          newData = newData.concat(data.data)
          this.setState({
            data: newData,
          })
        }
      }
    )
  }

  componentDidMount() {
    this.fetchNewsList()
  }

  research = () => {
    this.pageNum = 0
    this.pageSize = this.initPageSize
    this.totalNum = 10000
    this.setState({
      data: [],
    })
    setTimeout(() => this.fetchNewsList(), 200)
  }

  render() {
    let history = this.props.history
    let isAll = false
    if (this.state.data.length >= this.totalNum) {
      isAll = true
    }

    return (
      <MyLayout>
        <p className="userTitleStyle">资讯浏览记录</p>
        <List
          renderFooter={() => (
            <Button
              loading={this.state.loading}
              onClick={() => {
                this.fetchNewsList()
              }}
            >
              {isAll
                ? '~客官，没有更多了~'
                : this.state.loading
                  ? '数据加载中，请稍后'
                  : '点击加载更多数据'}
            </Button>
          )}
        >
          {this.state.data.map((item, index) => (
            <Item
              key={index}
              multipleLine
              onClick={() =>
                history.push(RoutePath.NewsDetail + '/' + item.newsId)
              }
              wrap
            >
              <Flex>
                <img
                  src={item.newsCoverpath}
                  style={{width: '30%', height: 70, marginRight: 20}}
                  alt=""
                />
                <div style={{width: '90%'}}>
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '70%',
                    }}
                  >
                    {item.newsTitle}
                  </div>
                  <Brief>{item.visitedTime}</Brief>
                </div>
              </Flex>
            </Item>
          ))}
        </List>
      </MyLayout>
    )
  }
}

export default NewsHistory
