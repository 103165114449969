import React, { Component } from 'react';
import MyLayout from '../../common/MyLayout';
import {Flex, SearchBar, List, Button, Tag, Badge, Tabs, Modal, WingBlank } from 'antd-mobile';
import api from '../../../api/api';
import RoutePath from '../../../config/RoutePath';
import { Action } from '../../../actions/Action';
import { connect } from 'react-redux';

const Item = List.Item;
const Brief = Item.Brief;

class MyMessageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            noticeType: '0',

            loading:false,
            data:[],
            detailData:{},
            visible:false,
        };

        this.initPageSize = 8;

        this.pageNum = 0;
        this.pageSize = this.initPageSize;
        this.totalNum = 10000;
    }

    fetchData()
    {
        if(this.state.data.length >= this.totalNum)
        {
            this.setState({
                loading:false,
            });
            return;
        }
        this.pageNum++;

        api.notice.searchToMeNotice(
            {
                "condition": {
                    "noticeType":this.state.noticeType,
                    "keyword":this.state.keyword
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.totalNum = data.totalNum;
                    let newData = this.state.data;
                    newData = newData.concat(data.data);
                    this.setState({
                        data:newData,
                    })
                }
            }
        )
    }

    componentDidMount()
    {
        this.fetchData();
    }

    research() {

        this.pageNum = 0;
        this.pageSize = this.initPageSize;
        this.totalNum = 10000;

        this.setState({
            data:[],
        })
        setTimeout(()=>this.fetchData(), 200,);
    }

    fetchNoticeDetail(noticeId)
    {
        api.notice.getNoticeDetail(
            {noticeId},
            (state, data)=>{
                if(state && data.status === 200) {
                    this.setState({
                        detailData:data.data,
                    });
                    //this.pageOnChange(this.pageNum,this.pageSize);
                    this.props.refreshNotReadNum();
                }
            }
        )
    }
    
    render() {

        let notReadNoticeNum = this.props.notReadNoticeNum;
        let history = this.props.history;
        let isAll = false;
        if(this.state.data.length >= this.totalNum)
        {
            isAll = true;
        }

        let tabs =[
            {
                title:
                <Badge
                 dot={notReadNoticeNum.numSys > 0}
                >
                系统消息
                </Badge>,
            },
            {
                title:
                <Badge
                 dot={notReadNoticeNum.numOrg > 0}
                >
                学校通知
                </Badge>,
            },
            {
                title:
                <Badge
                 dot={notReadNoticeNum.numCls > 0}
                >
                班级通知
                </Badge>,
            },
            {
                title:
                <Badge
                 dot={notReadNoticeNum.numDes > 0}
                >
                定向通知
                </Badge>,
            }
        ];

        return (
            <MyLayout title='我的消息'>
                <Flex>
                    <Flex.Item style={{flex:1}}>
                        <SearchBar
                            placeholder="关键字"
                            onChange={value=>this.setState({keyword:value})}
                            onSubmit={value => this.research()}
                        />
                    </Flex.Item>
                </Flex>
                <div>
                <Tabs 
                    tabs={tabs}
                    swipeable={false}
                    page={this.state.noticeType}
                    onTabClick={(tab,index)=>{
                        this.setState({
                            noticeType:index,
                        })
                        this.research();
                    }}
                    >
                    {
                        tabs.map((item,index)=><div key={index}></div>)
                    }
              </Tabs>
                <List
                    renderFooter={()=>(
                        <Button
                        loading={this.state.loading}
                        onClick={
                            ()=>
                            {
                                this.fetchData();
                            }
                        }
                        >{isAll?
                        '~客官，没有更多消息了~':
                        (this.state.loading?
                        '消息加载中，请稍后':
                        '点击加载更多消息')
                        }
                        </Button>
                    )}
                    >
                        {
                            this.state.data.map(
                                (item,index)=>(
                                    <Item
                                    key={index}
                                    multipleLine	
                                    arrow="horizontal"
                                    onClick={()=>{
                                        this.fetchNoticeDetail(item.noticeId);
                                         this.setState({visible:true})
                                    }
                                    }
                                    wrap
                                    >
                                     {item.title}
                                    <Brief>{item.userName}&nbsp; &nbsp; <Badge dot={item.readTime?false:true}>{item.readTime?"已读":"未读"}</Badge></Brief>
                                    </Item>
                                )
                            )
                        }
                    </List>
                    </div>
                    <Modal                
                    title={this.state.detailData.title}
                    visible={this.state.visible}
                    transparent
                    maskClosable={false}
                    onClose={()=>{
                                this.setState({
                                    visible:false
                                })
                            }}
                    footer={[{
                            text: '确定', 
                            onPress: () => { 
                                this.setState({
                                    visible:false
                                })
                            } }]
                        }
                >
                {
                  this.state.detailData.content?
                  this.state.detailData.content.split("\n").map(
                    (txt,index)=>(
                        <div key={10000+index}>{txt}</div>
                    )
                ):null
                }
                </Modal>
            </MyLayout>
        );
    }
}

function mapStateToProps(state) {
    return {
        notReadNoticeNum: state.myUser.notReadNoticeNum,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        refreshNotReadNum: (data) => dispatch({
            type: Action.FEATCH_READ_NUM,
        })
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MyMessageList);

