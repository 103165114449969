import React, {useEffect, useState} from 'react'
import {InputItem, Toast, Button, WhiteSpace, WingBlank} from 'antd-mobile'
import certificateLogo from '../assets/images/certificate.jpg'

const CertificateQuery: React.FC = () => {
  const [hasError, setHasError] = useState(false)
  const [value, setValue] = useState('')
  const [visibility, setVisibility] = useState(false)
  const [certificateId, setCertificateId] = useState('')

  useEffect(() => {
    window.location.href='http://www.chinagjwdx.com/zscx/zscx.html'
    document.title = '证书查询'
  }, [])

  const onErrorClick = () => {
    if (hasError) {
      Toast.info('证书编号格式有误')
    }
  }

  const onChange = (e) => {
    if (/^[0-9a-zA-Z]{16}$/.test(e)) {
      setHasError(false)
    } else {
      setHasError(true)
    }
    setValue(e)
  }

  const onSubmit = () => {
    if (value === '') {
      Toast.info('证书编号格式有误')
      return
    }
    if (!hasError) {
      setVisibility(true)
      setCertificateId(value)
    } else {
      onErrorClick()
    }
  }

  return (
    <div>
      <img className='certificateLogo' src={certificateLogo} alt=""/>
      <InputItem
        placeholder="请输入证书编号"
        error={hasError}
        onErrorClick={onErrorClick}
        onChange={onChange}
        value={value}
      >证书查询
      </InputItem>
      <WhiteSpace/>
      <WhiteSpace/>
      <WhiteSpace/>
      <WingBlank>
        <Button type="primary" onClick={onSubmit}>点击查询</Button>
        {visibility &&
        <img className='certificateImg' src={'api/anon/getVideoPlayCertPng?certNo=' + certificateId} alt=""/>}
      </WingBlank>
    </div>
  )
}

export {CertificateQuery}