
import React, { Component } from 'react';
import MyLayout from '../../common/MyLayout';
import {List, Tag, Button, Progress} from 'antd-mobile';
import api from '../../../api/api';
import RoutePath from '../../../config/RoutePath';
const Item = List.Item;
const Brief = Item.Brief;

class AnswerRecord extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:[],
         };
    }

    fetchMyPaperRecord()
    {
        api.exam.getMyAnswerRecord(
            null,
            (state,data)=>{
                if(state && data.status === 200)
                {
                    this.setState({
                        data:data.data
                    })
                }
            }
        )
    }

    componentDidMount()
    {
        this.fetchMyPaperRecord();
    }

    render() {
        let history = this.props.history;
        return (
            <MyLayout title="答卷列表">
                <List>
                        {
                            this.state.data.map(
                                (item,index)=>(
                                    <Item
                                    key={index}
                                    multipleLine
                                    arrow="horizontal"
                                    extra={
                                        <>
                                        <div>得分:<span style={{color:'red'}}>{item.choiceScore+item.completionScore+item.shortScore}</span>分</div>
                                        <div>{
                                        item.judge == 0?
                                        <span>
                                            <Tag style={{backgroundColor:"#f50", color:"white"}}
                                                 small>
                                                    未判卷
                                            </Tag>
                                        </span>
                                        :<span>
                                            <Tag style={{backgroundColor:"#87d068", color:"white"}}
                                                 small>
                                                    已判卷
                                            </Tag>
                                            </span>
                                        }</div>
                                        </>
                                    }
                                    onClick={()=>history.push(
                                        RoutePath.AnswerDetail + "/" + item.paperId
                                    )}
                                    >

                                     <>{item.title}</>
                                     <Brief>
                                         <div className="show-info">
                                             <div className="progress">
                                                 <Progress percent={item.percent}
                                                           position="normal" />
                                             </div>
                                             <Tag style={{backgroundColor:"#f4a231", color:"white"}}
                                                  small>
                                                 <div aria-hidden="true">已答{item.percent}%</div>
                                             </Tag>
                                         </div>
                                     </Brief>
                                    </Item>
                                )
                            )
                        }
                    </List>
                    {
                        this.state.data.length === 0 ?
                        <div>
                            <Button>~客官，您还没有做过练习呢~</Button>
                        </div>
                        :null
                    }
            </MyLayout>
        );
    }
}

export default AnswerRecord;
