import React,{ Component } from "react";
import {connect } from 'react-redux';
import {Flex,List,WhiteSpace, Grid, Badge} from "antd-mobile";
import MyLayout from "../../common/MyLayout";
import '../../../assets/style/common.css';
import login from "../../../assets/images/login.jpg";
import RoutePath from "../../../config/RoutePath";
import notUploaded from "../../../assets/images/notUploaded.jpg";

const Item = List.Item;
const Brief = Item.Brief;

class UserInfo extends Component{

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        let userInfo = this.props.userInfo;
        let history = this.props.history;
        let nrn = this.props.notReadNoticeNum;
        let totalNotice = nrn.numSys+nrn.numOrg+nrn.numCls+nrn.numDes;

        let role = -1;
        if(userInfo)
        {
            role = userInfo.role;
        }

        let memuData = [];
        if(role === 2)
        {
            memuData = [
                {
                    img: require("../../../assets/images/ranswer.png"),
                    title: "出题预览",
                    toWhere: RoutePath.MyPaperRecord,
                },
            ];
        }

        if(role === 0)
        {
          memuData = [
            {
                img: require("../../../assets/images/rplay.png"),
                title: "播放记录",
                toWhere: RoutePath.PlayRecord,
            },
            {
                img: require("../../../assets/images/ranswer.png"),
                title: "答题记录",
                toWhere: RoutePath.AnswerRecord,
            },
            {
                img: require("../../../assets/images/rclass.png"),
                title: "我的班级",
                toWhere: RoutePath.MyClassRecord,
            },
            {
                img: require("../../../assets/images/rtask.png"),
                title: "视频任务",
                toWhere: RoutePath.MyTaskRecord+"/0",
            },
            {
                img: require("../../../assets/images/rexam.png"),
                title: "考试任务",
                toWhere: RoutePath.MyTaskRecord+"/1",
            },
            {
                img: require("../../../assets/images/rmessage.png"),
                title: "我的消息",
                toWhere: RoutePath.MyMessageList,
                totalNotice: totalNotice,
            },
            {
                img: require("../../../assets/images/statistics.png"),
                title: "学习信息统计",
                toWhere: RoutePath.FetchPersonalStatics,
            },
            {
                img: require("../../../assets/images/rhistory.png"),
                title: "资讯浏览记录",
                toWhere: RoutePath.NewsHistory,
            },
          ];
        }

        return(
            <MyLayout title="我的信息">
                <List className="my-list">
                    <div>
                        {
                            userInfo?
                            <div className="userInfo-intro" onClick={() => {history.push(RoutePath.InfoClass)}}>
                                <div className="userInfo-img">
                                    <img
                                        src={userInfo.profile?userInfo.profile:notUploaded}
                                    />
                                </div>
                                <div className="userInfo-content">
                                    <p className="userInfo-name">
                                        {userInfo.name?userInfo.name:""}
                                    </p>
                                </div>
                                <div className="userInfo-edit">
                                    编辑
                                </div>
                            </div>
                            :
                            <div style={{marginTop:"5px",marginBottom:'5px'}} onClick={() => {history.push(RoutePath.Login)}}>
                                <div style={{textAlign:'center'}}>
                                    <img src={login} alt=""/>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <span>登陆</span>
                                </div>
                            </div>
                        }
                    </div>
                </List>
                <WhiteSpace size="lg" />
                <Grid data={memuData}
                    renderHeader="百宝箱"
                    columnNum={3}
                    renderItem={dataItem => (
                        <div style={{ padding: '12.5px' }} 
                        onClick={
                            ()=>history.push(dataItem.toWhere)
                        }>
                            {
                                dataItem.totalNotice?
                                <Badge dot={dataItem.totalNotice>0}>
                                    <img src={dataItem.img} style={{ width: '45px', height: '45px' }} alt="" />
                                </Badge>
                                :
                                <img src={dataItem.img} style={{ width: '45px', height: '45px' }} alt="" />
                            }
                            
                            <div style={{ color: '#888', fontSize: '14px', marginTop: '2px' }}>
                                <span>{dataItem.title}</span>
                            </div>
                        </div>
                    )}
                />
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.myUser.userInfo,
        notReadNoticeNum: state.myUser.notReadNoticeNum,
    };
}

export default connect(mapStateToProps,null)(UserInfo);

