
let ComFunction = {

    objExtMixin:(target,mixobj)=>{
        let objAll = Object.assign(target,mixobj);
        return Object.assign(objAll,target);
    },

    getScareImgeUrl:(orgFilePath,w,h)=>{
        return "api/authc/download"+orgFilePath+"?w="+w+"&h="+h;
    },


    getVisitedTimesDisplayText:(visitedTimes)=>{
	
        if(!visitedTimes)
        {
            return '0';
        }
    
        if(visitedTimes < 10000)
        {
            return ''+visitedTimes;
        }
    
        return (visitedTimes/10000).toFixed(2)+"w+"
    },
    
}

export default ComFunction;
