import React from 'react'

import api from '../../../api/api'
import {Tabs} from 'antd-mobile'
import F2 from '@antv/f2'

class PersonalSnapshots extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      staticsData: [],
      activeKey: 'numVideo',
    }
  }

  fetchPersonalSnapshots = () => {
    api.statics.fetchPersonalSnapshots(
      {},
      (state, data) => {
        if (state && data.status === 200) {
          this.setState({
            staticsData: data.data,
          })
        }
      }
    )
  }

  getSpecifyData = (totalData, keyword) => {
    let data = []
    for (let i = 0; i < totalData.length; i++) {
      if (totalData[i][keyword] !== 0) {
        data.push({
          time: totalData[i].updateTime,
          value: totalData[i][keyword],
        })
      }
    }
    return data
  }

  initDraw = data => {
    const chart = new F2.Chart({
      id: 'container',
      pixelRatio: window.devicePixelRatio,
    })
    this.chart = chart
    chart.source(data, {
      targetValue1: {
        tickCount: 5,
      },
    })
    chart.source(data, {
      time: {
        tickCount: 3
      }
    })
    chart.tooltip({
      showItemMarker: false,
      onShow: function onShow(ev) {
        const items = ev.items
        items[0].name = items[0].title
      }
    })

    chart.interval().position('time*value')
    chart.render()
  }

  componentDidMount() {
    this.fetchPersonalSnapshots()
  }

  render() {
    const {staticsData, activeKey} = this.state
    let newData = []
    const radioData = [
      {sub: 0, title: '视频数', value: 'numVideo'},
      {sub: 1, title: '学习时长', value: 'numVideoDuration'},
      {sub: 2, title: '试卷数', value: 'numAnswer'},
      {sub: 3, title: '答题数', value: 'numAnswerItems'},
    ]

    if (staticsData && staticsData.length > 0) {
      newData = this.getSpecifyData(staticsData, activeKey)
      this.initDraw(newData)
    }

    return (
      <>
        <Tabs tabs={radioData} initialPage={0} onChange={e => this.setState({activeKey: e.value})}/>
        <canvas id="container" width={window.innerWidth} height="280"/>
      </>
    )
  }
}

export default PersonalSnapshots