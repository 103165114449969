import React,{ Component } from "react";
import {connect } from 'react-redux';
import {withRouter, Route } from 'react-router'
import {TabBar, NavBar, Icon, Badge, Popover} from "antd-mobile";

import {Action} from '../../actions/Action'
import RoutePath from "../../config/RoutePath";
import question from "../../assets/images/question.png";
const Item = Popover.Item;

let gHistorys = [];
class MyLayout extends Component{

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            fullScreen: false,
        };
    }

    lastIndexKey(params) {
        let index = params.lastIndexOf("\/");
        return params.substring(index + 1, params.length);
    }

    restructHistorys()
    {
        let pathname = this.props.location.pathname;
        if(gHistorys.length > 0)
        {
            if(gHistorys[gHistorys.length -1] != pathname)
            {
                gHistorys.push(pathname);
            }
        }
        else
        {
            gHistorys.push(pathname);
        }
    }


    findInitialPage(keys)
    {
        for(let i = gHistorys.length -1; i >= 0; i--)
        {
            for(let j = 0; j < keys.length; j++)
            {
                if(this.lastIndexKey(gHistorys[i]) == this.lastIndexKey(keys[j].path))
                {
                    return j;
                }
            }
        }
        return 0;
    }
    onSelect = (opt) => {
        if (opt.key == "qCode") {

            let qrUrl = RoutePath.ThirdQrReader;
            if(this.props.userInfo)
            {
                qrUrl = qrUrl + "?token=" + this.props.userInfo.token;
            }
            this.props.history.push(qrUrl);
        }
        if (opt.key == "qa") {
            this.props.history.push(RoutePath.GetMyCreatedTopicList);
        }
        this.setState({
            visible: false,
        });
    };

    render()
    {
        this.restructHistorys();
        let history = this.props.history;
        let nrn = this.props.notReadNoticeNum;
        let totalNotice = nrn.numSys+nrn.numOrg+nrn.numCls+nrn.numDes;
        const TabPaths = [
            {
                path: RoutePath.Home,
                icon: <Badge
                   >
                    <img alt='图像不存在' 
                    src={require('../../assets/images/home01.png')} 
                    width={20} height={20}/>
                    </Badge>,
                selectedIcon:<Badge>
                    <img alt='图像不存在' 
                    src={require('../../assets/images/home02.png')} 
                    width={20} height={20}/>
                    </Badge>,
                title:'首页',
            },
            {
                path: RoutePath.SeriesPub,
                icon: <Badge>
                    <img alt='图像不存在' 
                    src={require('../../assets/images/sort01.png')} 
                    width={20} height={20}/>
                    </Badge>,
                selectedIcon:<Badge>
                    <img alt='图像不存在' 
                    src={require('../../assets/images/sort02.png')} 
                    width={20} height={20}/>
                    </Badge>,
                title:'系列',
            },
            {
                path: RoutePath.NewsList,
                icon: <Badge>
                    <img alt='图像不存在' 
                    src={require('../../assets/images/news01.png')} 
                    width={20} height={20}/>
                    </Badge>,
                selectedIcon:<Badge>
                    <img alt='图像不存在' 
                    src={require('../../assets/images/news02.png')} 
                    width={20} height={20}/>
                    </Badge>,
                title:'资讯',
            },
            {
                path: RoutePath.UserInfo,
                icon: <Badge
                       dot ={totalNotice>0}
                     >
                    <img alt='图像不存在' 
                    src={require('../../assets/images/user01.png')} 
                    width={20} height={20}/>
                    </Badge>,
                selectedIcon:<Badge
                     dot ={totalNotice>0}
                     >
                    <img alt='图像不存在' 
                    src={require('../../assets/images/user02.png')} 
                    width={20} height={20}/>
                    </Badge>,
                title:'我的',
            }
        ];
        let initialPage = this.findInitialPage(TabPaths);

        return(
            <div style={{ position: 'fixed', height: '100%', width: '100%', top: 0}}>
            <TabBar
                unselectedTintColor="#949494"
                tintColor="#33A3F4"
                barTintColor="white"
                tabBarPosition="bottom"
            >
                {
                    TabPaths.map((item,index)=>(
                        <TabBar.Item
                        title={item.title}
                        key={"100"+index}
                        icon={item.icon}
                        selectedIcon={item.selectedIcon}
                        onPress={() => {
                            history.push(item.path);
                        }}
                        selected={index === initialPage}
                        >
                        <>
                        {
                            index === initialPage?
                            <>
                            {this.props.hideNaviBar?<></>:
                            <NavBar
                            mode="dark"
                            leftContent={[
                                <Icon key="11" type="left" 
                                    onClick={
                                        ()=>history.goBack()
                                    }
                                />,
                            ]}
                            rightContent={
                                <Popover mask
                                         overlayClassName="fortest"
                                         overlayStyle={{ color: 'currentColor' }}
                                         visible={this.state.visible}
                                         overlay={[
                                             (<Item key="qCode"
                                                    icon={<img src={require("../../assets/images/qCode.svg")}
                                                               className="am-icon am-icon-xs"
                                                               alt="图片未加载"/>}>三方登录
                                             </Item>),
                                             (<Item key="qa"
                                                    icon={<img src={require("../../assets/images/qa.svg")}
                                                               className="am-icon am-icon-xs"
                                                               alt="图片未加载" />}>教师答疑
                                             </Item>),
                                         ]}
                                         align={{
                                             overflow: { adjustY: 0, adjustX: 0 },
                                             offset: [-10, 0],
                                         }}
                                         onVisibleChange={(visible)=>{this.setState({visible})}}
                                         onSelect={this.onSelect}
                                >
                                    <div style={{
                                        height: '100%',
                                        padding: '0 15px',
                                        marginRight: '-15px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    >
                                        <Icon type="ellipsis" />
                                    </div>
                                </Popover>
                            }
                            >{this.props.title?this.props.title:"学习平台"}</NavBar>}

                            {this.props.children}
                            </>
                            :
                            <div></div>
                        }
                        </>
                        </TabBar.Item>
                    ))
                }
            </TabBar>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.myUser.userInfo,
        notReadNoticeNum: state.myUser.notReadNoticeNum,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetUser: (data) => dispatch({
            type: Action.RESET_USER_INFO,
        }),
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MyLayout));