'use strict'

import { combineReducers } from 'redux'
import UserInfo from './UserInfo'

const ReducerRoot = combineReducers({
  myUser:UserInfo,
})

export default ReducerRoot;
