
import React, { Component } from 'react';
import { Toast, Checkbox, List, Stepper, Tabs, TextareaItem, Button, Flex, Modal } from 'antd-mobile';
import api from '../../../api/api';
import { connect } from 'react-redux';
import PackButton from '../../../components/PackButton';
const CheckboxItem = Checkbox.CheckboxItem;
const Item = List.Item;
const Brief = Item.Brief;

class AnswerItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            change: false,
            ansContent:"NaN",
            modalVisible:false,
         };
    }

    getSortString(str)
    {
        if(!str)
        {
            return "";
        }

        if(str.length === 0){
            return "";
        }

        if(str.length === 1){
            return str;
        }
        var arrayStr = str.split('');
        var newArr = arrayStr.sort(function(a,b){return a.localeCompare(b)});
        return newArr.join("");
	}

    getSingleChoiceCheckState(ansContent,choice,answer)
    {
        if(ansContent === "NaN")
        {
            if(answer)
            {
                return answer.ansContent === choice.tmark;
            }
            else
            {
                return false;
            }
        }
        else
        {
            return ansContent === choice.tmark;
        }
    }

    getMultiChoiceCheckState(ansContent,choice,answer)
    {
        if(ansContent === "NaN")
        {
            if(answer)
            {
                return answer.ansContent.indexOf(choice.tmark) >= 0;
            }
            else
            {
                return false;
            }
        }
        else
        {
            return ansContent.indexOf(choice.tmark) >= 0;
        }
    }


    getFillValue(ansContent,answer)
    {
        if(ansContent === "NaN")
        {
            if(answer)
            {
                return answer.ansContent;
            }
            else
            {
                return null;
            }
        }
        else
        {
            return ansContent;
        }
    }

    getRemoveMarkFromStr(str,mark)
    {
        var res = "";
        for(var i = 0; i < str.length; i++)
        {
            if(str[i] !== mark)
            {
                res += str[i]
            }
        }
        return res;
    }

    getCompareStrByType(str1,str2,type)
    {
        if(type > 0 && type < 3)
        {
            return this.getSortString(str1) ===  this.getSortString(str2);
        }
        else
        {
            return str1 === str2;
        }
    }

    sumBitAnswer()
    {
        let role = 0;
        if(this.props.userInfo)
        {
            role = this.props.userInfo.role;
        }
        if(role !== 0)
        {
            Toast.fail('老师只能浏览，不能答题');
            return;
        }

        let {answer} = this.props.question;
        let {ansContent} = this.state;
        if(this.getIsNeedSumbit())
        {
            if(answer)
            {
                answer.ansContent=ansContent;
            }
            else
            {
                answer = {
                    answerId: this.props.answerId,
                    questionId: this.props.question.questionId,
                    ansContent: ansContent,
                }
            }
            api.exam.addOrEditAnser(
                answer,
                (state,data)=>
                {
                    if(state && data.status === 200)
                    {
                        if(this.props.onAnswerSubmit)
                        {
                            this.props.onAnswerSubmit(true);
                        }
                    }
                    else
                    {
                        Toast.fail(data.msg);
                    }
                }
            )
        }
        else
        {
            Toast.fail("没什么要提交的");
        }
    }

    getIsNeedSumbit()
    {
        let {answer,examQuestion} = this.props.question;
        let {ansContent,change} = this.state;
        if(change)
        {
            if(answer)
            {
                if(this.getCompareStrByType(answer.ansContent,ansContent,examQuestion.itemType))
                {
                    //答案未变
                    return  false;
                }
                else
                {
                    //答案变更
                    return true;
                }
            }
            else
            {
                //新答题了
                return true;
            }
        }
        else
        {
            return false;
        }
    }
    
    getSignText()
    {
        let {answer,examQuestion} = this.props.question;
        let {ansContent,change} = this.state;
        if(change)
        {
            if(answer)
            {
                if(this.getCompareStrByType(answer.ansContent,ansContent,examQuestion.itemType))
                {
                    //答案未变
                    return "答案未变更，不需要重新提交"
                }
                else
                {
                    //答案变更
                    return <span style={{color:"red"}}>答案变更，未提交</span>
                }
            }
            else
            {
                return <span style={{color:"red"}}>已答题，未提交</span>
            }
        }
        else
        {
            if(answer)
            {
                return "答案已提交";
            }
            else
            {
                return <span style={{color:"red"}}>本题目还未解答</span>;
            }
        }
    }

    render() {
        let {answer,choices,examQuestion,questionId} = this.props.question;
        let {ansContent,change} = this.state;
        let questionType = ['单选题','多选题','不定向选择题','填空题','简答题','判断题'];

       
        return (
            <div style={{margin:"5%"}}>
                    <p style={{backgroundColor:"white",padding:"7px"}}>
                        {examQuestion?questionType[examQuestion.itemType]:""}({examQuestion?examQuestion.itemScore:0}分)
                    </p>
                {
                  examQuestion?
                  <div dangerouslySetInnerHTML = {{ __html: examQuestion.content }} />
                  :null
               }

               {
                  //单选
                  choices 
                  && examQuestion 
                  && examQuestion.itemType === 0?
                  <List>
                    {
                      choices.map((choice,index)=>(
                          <CheckboxItem 
                          key={index} 
                          checked={
                              this.getSingleChoiceCheckState(ansContent,choice,answer)
                          }
                          onChange={()=>this.setState({
                            change:true,
                            ansContent:choice.tmark
                          })}
                          >
                            <Flex>
                                <Flex.Item>
                                        <div dangerouslySetInnerHTML = {{ __html: choice.content }} />
                                </Flex.Item>
                            </Flex>
                          </CheckboxItem>
                      ))
                    }
                  </List>:null
              }

              {
                  //多选，不定向选
                  choices 
                  && examQuestion 
                  && (examQuestion.itemType === 1 
                  || examQuestion.itemType === 2)?
                  <List>
                    {
                      choices.map((choice,index)=>(
                          <CheckboxItem 
                          key={index} 
                          checked={
                              this.getMultiChoiceCheckState(ansContent,choice,answer)
                          }
                          onChange={()=>{

                            let ansContent = this.state.ansContent;
                            if(ansContent === "NaN")
                            {
                                if(answer)
                                {
                                    //已有答案
                                    ansContent = answer.ansContent;
                                }
                                else
                                {
                                    //没有答案
                                    ansContent = "";
                                }
                            }

                            //原来已经选择
                            if(this.getMultiChoiceCheckState(ansContent,choice,answer))
                            {
                                ansContent = this.getRemoveMarkFromStr(ansContent,choice.tmark);
                            }
                            else
                            {
                                ansContent = ansContent + choice.tmark;
                                ansContent = this.getSortString(ansContent);
                            }

                            this.setState({
                                change: true,
                                ansContent:ansContent
                            });

                          }}>
                            <div dangerouslySetInnerHTML = {{ __html: choice.content }} />
                          </CheckboxItem>
                      ))
                    }
                  </List>:null
              }

              {
                examQuestion && 
                (examQuestion.itemType === 3 
                || examQuestion.itemType === 4)?
                <TextareaItem
                 placeholder="请输入答案"
                 autoHeight
                 value={this.getFillValue(ansContent,answer)}
                 onChange={
                     (ansContent)=>{
                        this.setState({
                                change: true,
                                ansContent:ansContent
                            });
                     }
                 }
                />:null
              }

              {
                examQuestion && 
                examQuestion.itemType === 5?
                <List>
                    <CheckboxItem 
                    checked={
                        this.getSingleChoiceCheckState(ansContent,{tmark:'T'},answer)
                    }
                    onChange={()=>this.setState({
                    change:true,
                    ansContent:'T'
                    })}
                    >
                    此说法正确
                    </CheckboxItem>
                    <CheckboxItem 
                    checked={
                        this.getSingleChoiceCheckState(ansContent,{tmark:'F'},answer)
                    }
                    onChange={()=>this.setState({
                    change:true,
                    ansContent:'F'
                    })}
                    >
                    此说法错误
                    </CheckboxItem>
                  </List>:null
              }

              <div></div>
                <Brief>
                    <p className="">
                        {
                            this.getSignText()
                        }
                    </p>
                </Brief>
              <PackButton style={{marginTop:"12px"}}
               disabled={!this.getIsNeedSumbit()}
               type="primary"
               onClick={
                   ()=>this.sumBitAnswer()
               }
               clickDelayTime={500}
              >
                  提交本题答案
              </PackButton>
              {
                  this.props.examType === 0?
                  <>
                  <Button 
                    style={{marginTop:"12px"}}
                    type="warning"
                    onClick={()=>{
                        this.setState({modalVisible:true})
                    }}
                    >
                    看答案</Button>
                  </>:null
              }
              <Modal 
              visible={this.state.modalVisible}
              transparent
              maskClosable={false}
              onClose={()=>{this.setState({modalVisible:false})}}
              title='标准答案'
              footer={[{ text: '确定', onPress: () => { this.setState({modalVisible:false}) } }]}
              >
                {
                  examQuestion?
                  <div dangerouslySetInnerHTML = {{ __html: examQuestion.standardAnswer }} />
                  :null
               }
              </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.myUser.userInfo,
    };
}

export default connect(mapStateToProps,null)(AnswerItem);

