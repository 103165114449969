
import React, { Component } from 'react';
import { List, InputItem, Toast, NavBar, WhiteSpace, WingBlank, Button, Flex, Switch, Tabs, Modal } from 'antd-mobile';
import { createForm } from 'rc-form';
import api from "../../api/api";
import DataValidate from "../../config/DataValidate";
import { Action } from '../../actions/Action';
import { connect } from 'react-redux';
import RoutePath from '../../config/RoutePath';

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isVisiblePhoneCode:false,
            imgenum:1325
        };
    }

    loginSuccess(data) {
        let user = data.data;
        if (user.role == 0)
        { 
            //普通用户
            this.props.saveUserInfoToStore(data.data);
            this.props.history.push('/home');
        }
        else
        {
            Toast.fail("只有学员用户可登录");
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let wxkey = this.getParam('wxkey');
                api.user.register(
                    { ...values, wxkey,role:0,name:"未指定"},
                    (state, data) => {
                        if (state && data.status === 200) {
                            this.loginSuccess(data);
                        } else {
                            Toast.fail(data.msg);
                        }
                    }
                )
            }
        });
    };

    getParam(name)
    {
        var search = this.props.location.search;
        if (search == "") {
            return null;
        };

        var query = search.substring(1);                                                                                                                                     
        var list = query.split('&');
        var item = [];
        for(var i = 0; i < list.length; i++) {
            item = list[i].split('=');
            if(item[0] == name)
            {
                return item[1];
            }
        };

        return null;
    }

    refreshImage() {
        this.setState(
            {
                imgenum:Math.ceil(Math.random()*1000000)
            }
        )
    }

    render() {

        let wxkey = this.getParam('wxkey');
        const { getFieldProps,getFieldError,validateFields,getFieldValue} = this.props.form;

        return (
                <>
                <form style ={{backgroundColor: 'white'}}>
                <WhiteSpace/>
                <Flex justify="center">
                     <img  alt="" src={require("../../assets/images/logo_m.png")} height={150}/>
                </Flex>
                <WingBlank>
                <List>
                    <List.Item>
                        <div></div>
                    </List.Item>
                    <InputItem
                        {...getFieldProps("phone",
                         {
                            validateFirst:true,
                            rules: [
                                {
                                    required: true,
                                    message:"必须输入"
                                },
                                {
                                        validator: (rule, value, callback) => {
                                            if (DataValidate.isMobile(value)) {
                                                callback();
                                            }
                                            else {
                                                callback('error');
                                            }
                                        },
                                        message: '电话格式不对',
                                },
                                {
                                    validator: (rule, value, callback) => { 
                                            api.user.basicinfocheck({
                                                "checkItem": value,
                                                "type": 1
                                            },
                                                (state, data) => {
                                                    if (state && data.status === 200 ) {
                                                        callback();
                                                    }
                                                    else {
                                                        callback("error");
                                                    }
                                            });
                                        },
                                        message: '电话已经注册过了！',
                                    },
                                ],
                         }
                        )}
                        error = {getFieldError('phone')}
                        placeholder="手机号"
                    />
                    {getFieldError('phone')?
                    <List.Item>
                        <div style={{color:"red"}}>
                            {getFieldError('phone')}
                        </div>
                    </List.Item>:null
                    }
                    <Flex>
                        <InputItem
                            {...getFieldProps("phoneValidateCode",
                            {
                                validateFirst:true,
                                rules: [
                                    {
                                        required: true,
                                        message:"必须输入"
                                    },
                                    {
                                            validator: (rule, value, callback) => {
                                                if (DataValidate.isMobileCode(value)) {
                                                    callback();
                                                    return;
                                                }
                                                callback('error');
                                            },
                                            message: '验证码格式不对',
                                    },
                                    ],
                            }
                            )}
                            error = {getFieldError('phoneValidateCode')}
                            placeholder="手机验证码"
                        />
                         <Button 
                        size='small'
                        onClick={
                            ()=>{
                                let value = this.props.form.getFieldsValue();
                                if(!getFieldError('phone') && value.phone)
                                {
                                   this.setState({isVisiblePhoneCode:true});
                                }
                                else
                                {
                                    Toast.fail('请先输入正确电话号码');
                                }
                            }
                        }
                        >获取验证码</Button>
                    </Flex>
                    {getFieldError('phoneValidateCode')?
                    <List.Item>
                        <div style={{color:"red"}}>
                            {getFieldError('phoneValidateCode')}
                        </div>
                    </List.Item>:null
                    }
                    <InputItem
                        {...getFieldProps("password",
                         {
                            validateFirst:true,
                            rules: [
                                {
                                    required: true,
                                    message:"必须输入"
                                },
                                {
                                        validator: (rule, value, callback) => {
                                            if (DataValidate.isPassWord(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '密码格式不对(6~16位数字加字母组合)',
                                    },
                                ],
                         }
                        )}
                        error = {getFieldError('password')}
                        type='password'
                        placeholder="请输入密码"
                    />
                    {getFieldError('password')?
                    <List.Item>
                        <div style={{color:"red"}}>
                            {getFieldError('password')}
                        </div>
                    </List.Item>:null
                    }
                    <InputItem
                        {...getFieldProps("password2",
                         {
                            validateFirst:true,
                            rules: [
                                {
                                    required: true,
                                    message:"必须输入"
                                },
                                {
                                    validator: (rule, value, callback) => {
                                            if (DataValidate.isPassWord(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '密码格式不对(6~16位数字加字母组合)',
                                },
                                {
                                    validator: (rule, value, callback) => {
                                            if (value === getFieldValue('password')) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '两次密码输入不相同',
                                    },
                                ],
                         }
                        )}
                        error = {getFieldError('password2')}
                        type='password'
                        placeholder="请再次输入密码"
                    />
                    {getFieldError('password2')?
                    <List.Item>
                        <div style={{color:"red"}}>
                            {getFieldError('password2')}
                        </div>
                    </List.Item>:null
                    }
                    <List.Item>
                        <div></div>
                    </List.Item>
                    <Button type="primary" onClick={this.handleSubmit}>注册</Button>
                    <List.Item>
                    </List.Item>
                    <List.Item>
                    {
                            wxkey ?
                            <a href={"/#"+RoutePath.Login+"?wxkey=" + wxkey}>已有账号，立即登陆?</a>
                            :
                            <a href={"/#"+RoutePath.Login}>已有账号，立即登陆?</a>
                    }
                    </List.Item>
                </List>
                </WingBlank>

                <Modal
                    visible={this.state.isVisiblePhoneCode}
                    transparent
                    closable={true}
                    onClose={
                        ()=>
                        this.setState({isVisiblePhoneCode:false})
                    }
                    title="发送验证码"
                    footer={
                        [
                            { text: '发送', onPress: 
                                () => { 
                                    let value = this.props.form.getFieldsValue();
                                    if(!getFieldError('validateKeyPhone') && value.validateKeyPhone)
                                    {
                                        api.user.sendPhoneKey({
                                                phone:value.phone,
                                                validatekey:value.validateKeyPhone,
                                            },
                                            (status,data)=>{
                                                if(status && data.status == 200) {
                                                    this.setState({
                                                        isVisiblePhoneCode:false,
                                                        }
                                                    );
                                                    Toast.info("验证码发送成功");
                                                } else {
                                                    Toast.fail(data.msg);
                                                }
                                            }
                                        );
                                    }
                                    else
                                    {
                                        Toast.fail('请先输入正确图片验证码');
                                    }
                               } 
                            }
                        ]}
                    >
                    {
                     this.state.isVisiblePhoneCode?
                     <Flex>
                        <InputItem
                            {...getFieldProps("validateKeyPhone",
                            {
                                rules: [
                                    {
                                        required: true,
                                        message:"必须输入"
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(value && value.length == 5) {
                                                api.user.validateImgCode({validateCode:value},
                                                    function (state,data) {
                                                        if(state && data.status == 200) {
                                                            callback()
                                                        } else {
                                                            callback('error');
                                                        }
                                                    }
                                                );
                                            } else {
                                                callback('error');
                                            }
                                        },
                                        message: '验证码输入错误',
                                    },
                                    ],
                            }
                            )}
                            error = {getFieldError('validateKeyPhone')}
                            placeholder="请输入图片验证码"
                        />
                        <img  
                          alt=""
                          onClick={()=>this.refreshImage()}                       
                          style={{width: 120, height: 40}}
                          src={'/api/user/generateCodeImg?imgnum'+this.state.imgenum}/>
                        </Flex>:null}
                    </Modal>

                </form>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.myUser.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveUserInfoToStore: (data) => dispatch({
            type: Action.SAVE_USER_INFO,
            data: data,
        }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(createForm()(Register));


