import React,{ Component } from "react";
import {connect } from 'react-redux';
import {PullToRefresh, Grid, SearchBar, Flex, Toast, NoticeBar, List, Button, Icon} from "antd-mobile";
import MyLayout from "./common/MyLayout";
import api from "../api/api";
import { Action } from "../actions/Action";
import Animation from "./common/Animation";
import ComFunction from "../api/ComFunction";

const Item = List.Item;
const Brief = Item.Brief;


class Home extends Component{

    constructor(props) {
        super(props);
        this.state = {
            videoListData:[],

            videoKeywords:[],
            loading: false,
            isAll:false,
        };

        this.pageNum = 0;
        this.pageSize = 5;
        this.totalNum = 9999;
    }

    pageOnChange() {
        setTimeout(()=>this.videoList(), 200);
    }

    videoList() {
        if(this.state.videoListData.length >= this.totalNum)
        {
            this.setState({
                isAll:true,
                loading:false})
            return;
        }
        this.pageNum++;

        this.setState({loading:true})
        api.video.videoList(
            {
                "condition": {
                    "price": 0,
                    "status":null,
                    "videoKeywords": this.state.videoKeywords
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                this.setState({loading:false})
                if(state && data.status==200) {
                    this.totalNum = data.totalNum;
                    if (data.data.length != 0) {
                        let newData=[];
                        let newSplitArr =[];
                        newData = this.state.videoListData;
                        newSplitArr = newData.concat(data.data);//不改变现有的数组
                        this.setState({
                            videoListData:newSplitArr,
                        })
                    }
                }
            }
        )
    }

    getParam(name) {
        var search = this.props.location.search;
        if (search == "") {
            return null;
        }

        var query = search.substring(1);                                                                                                                                     
        var list = query.split('&');
        var item = [];
        for(var i = 0; i < list.length; i++) {
            item = list[i].split('=');
            if(item[0] == name) {
                return item[1];
            }
        }
        return null;
    }

    loginSuccess(data) {
        let user = data.data;
        if (user.role === 0)
        {
            this.props.saveUserInfoToStore(data.data);
        }
        else
        {
            Toast.fail("只有学员用户可登录");
        }
    }

    startUpLogin() {
        let token = this.getParam('token'); 
        if(token) {
            api.user.tokenLogin({token},
                (state,data)=>{
                if(state && data.status === 200) {
                    this.loginSuccess(data);
                } else {
                    Toast.fail(data.msg);
                }
            });
        }      
    }

    ListSearchBar(){
        return(
            <Flex>
                <Flex.Item style={{flex:7}}>
                    <SearchBar
                        placeholder="视频关键字"
                        onSubmit={value => this.research()}
                        onChange={
                            (value)=>{
                                if(value)
                                {
                                    this.setState({videoKeywords:[value]})
                                }
                                else
                                {
                                    this.setState({videoKeywords:null})
                                }
                            }
                        }
                    />
                </Flex.Item>
            </Flex>

        );
    }

    research=()=> {

        this.setState({
            videoListData:[],
            refreshing: false,
            isAll:false,
        });
        this.pageNum = 0;
        this.pageSize = 5;
        this.totalNum = 9999;

        setTimeout(()=>this.videoList(), 200,)
    };

    componentDidMount() {
        this.videoList();
        this.startUpLogin();
    }

    render() {
        let history = this.props.history;
        return(
            <MyLayout title="首页">
                {this.ListSearchBar()}
                <Animation/>
                <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}>
                    通知: 重视自身健康，务必做好自我防护! 坚定信心、同舟共济、科学防治、精准施策，坚决打赢疫情防控阻击战!
                </NoticeBar>
                <p className="userTitleStyle">课程推荐</p>
                    <List
                    renderFooter={()=>(
                        <Button
                        loading={this.state.loading}
                        onClick={
                            ()=>
                            {
                                this.videoList();
                            }
                        }
                        >{this.state.isAll?
                        '~客官，没有更多了~':
                        (this.state.loading?
                        '数据加载中，请稍后':
                        '点击加载更多数据')
                        }
                        </Button>
                    )}
                    
                    >
                        {
                            this.state.videoListData.map(
                                (item,index)=>(
                                    <Item
                                    key={index}
                                    multipleLine
                                    wrap
                                    onClick={()=> history.push('/videoPlay'+'/'+item.vid)}
                                    >
                                        <div className="homeTitleName">
                                            {item.name}
                                        </div>
                                        <img src={ComFunction.getScareImgeUrl(item.posterPath,270,140)}
                                        style={{ width: '100%', height: '50%' }}
                                        alt="" />
                                        <Flex justify="between" style={{marginRight:10}}>
                                            <Brief>{item.createdTime}</Brief>
                                            <Brief>
                                            <img alt="" src={require("../assets/images/play.jpg")}/>
                                            {ComFunction.getVisitedTimesDisplayText(item.visitTimes)}
                                            </Brief>
                                        </Flex>
                                        <Brief>{item.intro}</Brief>
                                    </Item>
                                )
                            )
                        }
                    </List>
            </MyLayout>
        )
    }

}

function mapStateToProps(state) {
    return {
      userInfo: state.myUser.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveUserInfoToStore: (data) => dispatch({
            type: Action.SAVE_USER_INFO,
            data: data,
        }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Home);

