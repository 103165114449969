import React, { Component } from 'react';
import { List, InputItem, Toast, NavBar, WhiteSpace, WingBlank, Button, Flex, Switch, Tabs, Modal } from 'antd-mobile';
import { createForm } from 'rc-form';
import api from "../../api/api";
import DataValidate from "../../config/DataValidate";
import { Action } from '../../actions/Action';
import { connect } from 'react-redux';
import RoutePath from '../../config/RoutePath';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNeedImageCode:false,
            imgenum:20,
            imgenum2:30,

            isUserPasswordLogin:true,
            isVisiblePhoneCode:false,
        };
    }

    loginSuccess(data) {
        let user = data.data;
        if (user.role === 0 || user.role === 2)
        { 
            //普通用户
            this.props.saveUserInfoToStore(data.data);
            this.props.history.push('/home');
        }
        else
        {
            Toast.fail("只有学员用户可登录");
        }
    }

    onSubmit = () => {
        this.props.form.validateFields({ force: true }, (error) => {
          if (!error) {

            let param =  
            {
                ...this.props.form.getFieldsValue(),
                wxkey:this.getParam("wxkey")
            };

            let cbk = (state,data)=>{
                this.refreshImage();
                if(state && data.status == 200)
                {
                    this.loginSuccess(data);
                }
                else if (state && data.status == 1014){
                    this.setState({
                        isNeedImageCode:true
                    });
                    Toast.fail("密码三次错误，需要验证码验证！");
                }else{
                    Toast.fail(data.msg);
                }
            };

            if(this.state.isUserPasswordLogin)
            {
                api.user.login(param,cbk);
            }
            else
            {
                api.user.phonelogin(param,cbk);
            }
          }
          else
          {
              Toast.fail("请修改输入错误");
          }
        });
    }

    getParam(name)
    {
        var search = this.props.location.search;
        if (search == "") {
            return null;
        };

        var query = search.substring(1);                                                                                                                                     
        var list = query.split('&');
        var item = [];
        for(var i = 0; i < list.length; i++) {
            item = list[i].split('=');
            if(item[0] == name)
            {
                return item[1];
            }
        };

        return null;
    }

    refreshImage() {
        this.setState(
            {
                imgenum:Math.ceil(Math.random()*1000000),
                imgenum2:Math.ceil(Math.random()*1000000)
            }
        )
    }

    render() {
        const { getFieldProps,getFieldError,validateFields } = this.props.form;
        let isUserPasswordLogin = this.state.isUserPasswordLogin;

        let wxkey = this.getParam('wxkey');

        let tabs =[
            {title:'用户名密码',isUserPasswordLogin:true},
            {title:'电话验证码',isUserPasswordLogin:false}
        ];

        return (
            <>
                <form style ={{backgroundColor: 'white'}}>
                <WhiteSpace/>
                <Flex justify="center">
                     <img src={require("../../assets/images/logo_m.png")} height={150}/>
                </Flex>
                <WingBlank>
                <List>
                    <List.Item>
                        <div></div>
                    </List.Item>

                    <Tabs 
                    tabs={tabs}
                    onChange={(tab, index) => { 
                        this.setState(
                            {
                                isNeedImageCode:false,
                                isUserPasswordLogin:tab.isUserPasswordLogin
                            })
                        }}
                    >
                    {isUserPasswordLogin?
                    <div>
                    <InputItem
                        {...getFieldProps("username",
                         {
                            rules: [
                                {
                                    required: true,
                                    message:"必须输入"
                                },
                                {
                                    validator: (rule, value, callback) => {
                                                if(DataValidate.isEmail(value)
                                                    ||DataValidate.isMobile(value)
                                                    ||DataValidate.isUid(value)
                                                    ||DataValidate.isIdNumber(value))
                                                {
                                                    
                                                    api.user.isNeedPictureValide({"username":value},
                                                        (state,data)=>{
                                                            if(state && data.status == 200 && data.data == 1) {
                                                                this.setState({
                                                                    isNeedImageCode:true
                                                                })
                                                            }
                                                            else
                                                            {
                                                                this.setState({isNeedImageCode:false});
                                                            }
                                                        });
                                                    callback();
                                                    return;
                                                }
                                                callback('error');
                                            },
                                            message: '输入格式不对',
                                },
                                ],
                         }
                        )}
                        error = {getFieldError('username')}
                        placeholder="手机号/邮箱/身份证号"
                    />
                    <InputItem
                        {...getFieldProps("password",
                         {
                            rules: [
                                {
                                    required: true,
                                    message:"必须输入"
                                },
                                ],
                         }
                        )}
                        type='password'
                        error = {getFieldError('password')}
                        placeholder="请输入密码"
                    />
                    </div>:<div></div>}

                    {!isUserPasswordLogin?
                    <div>
                    <InputItem
                        {...getFieldProps("phone",
                         {
                            rules: [
                                {
                                    required: true,
                                    message:"必须输入"
                                },
                                {
                                    validator: (rule, value, callback) => {
                                                if(DataValidate.isMobile(value))
                                                {
                                                    
                                                    api.user.isNeedPictureValide({"username":value},
                                                        (state,data)=>{
                                                            if(state && data.status == 200 && data.data == 1) {
                                                                this.setState({
                                                                    isNeedImageCode:true
                                                                })
                                                            }
                                                            else
                                                            {
                                                                this.setState({isNeedImageCode:false});
                                                            }
                                                        });
                                                    callback();
                                                    return;
                                                }
                                                callback('error');
                                            },
                                            message: '输入格式不对',
                                },
                                ],
                         }
                        )}
                        error = {getFieldError('phone')}
                        placeholder="手机号"
                    />
                    <Flex>
                        <InputItem
                         {...getFieldProps("phoneValidateCode",
                         {
                            rules: [
                                {
                                    required: true,
                                    message:"必须输入"
                                }
                                ],
                         }
                        )}
                        placeholder='手机验证码'
                        error = {getFieldError('phoneValidateCode')}
                        />
                        <Button 
                        size='small'
                        onClick={
                            ()=>{
                                let value = this.props.form.getFieldsValue();
                                if(!getFieldError('phone') && value.phone)
                                {
                                   this.setState({isVisiblePhoneCode:true});
                                }
                                else
                                {
                                    Toast.fail('请先输入正确电话号码');
                                }
                            }
                        }
                        >获取验证码</Button>
                    </Flex>                        
                    </div>:<div></div>}
                    </Tabs>
                    {
                        this.state.isNeedImageCode?
                        <Flex>
                        <InputItem
                            {...getFieldProps("validateKey",
                            {
                                rules: [
                                    {
                                        required: true,
                                        message:"必须输入"
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(value && value.length == 5) {
                                                api.user.validateImgCode({validateCode:value},
                                                    function (state,data) {
                                                        if(state && data.status == 200) {
                                                            callback()
                                                        } else {
                                                            callback('error');
                                                        }
                                                    }
                                                );
                                            } else {
                                                callback('error');
                                            }
                                        },
                                        message: '验证码输入错误',
                                    },
                                    ],
                            }
                            )}
                            error = {getFieldError('validateKey')}
                            placeholder="请输入图片验证码"
                        />
                        <img  
                          alt=""
                          onClick={()=>this.refreshImage()}                       
                          style={{width: 120, height: 40}}
                          src={'/api/user/generateCodeImg?imgnum'+this.state.imgenum2}/>
                        </Flex>:null
                    }
                    <Button type="primary" onClick={this.onSubmit}>登陆</Button>
                    <List.Item>
                    </List.Item>
                    <List.Item>
                    {
                            wxkey ?
                            <a href={"/#"+RoutePath.Register+"?wxkey=" + wxkey}>还没有账号，立即注册?</a>
                            :
                            <a href={"/#"+RoutePath.Register}>还没有账号，立即注册?</a>
                    }
                    </List.Item>
                </List>

                    <Modal
                    visible={this.state.isVisiblePhoneCode}
                    transparent
                    closable={true}
                    onClose={
                        ()=>
                        this.setState({isVisiblePhoneCode:false})
                    }
                    title="发送验证码"
                    footer={
                        [
                            { text: '发送', onPress: 
                                () => { 
                                    let value = this.props.form.getFieldsValue();
                                    if(!getFieldError('validateKeyPhone') && value.validateKeyPhone)
                                    {
                                        api.user.sendPhoneKey({
                                                phone:value.phone,
                                                validatekey:value.validateKeyPhone,
                                            },
                                            (status,data)=>{
                                                if(status && data.status == 200) {
                                                    this.setState({
                                                        isVisiblePhoneCode:false,
                                                        }
                                                    );
                                                    Toast.info("验证码发送成功");
                                                } else {
                                                    Toast.fail(data.msg);
                                                }
                                            }
                                        );
                                    }
                                    else
                                    {
                                        Toast.fail('请先输入正确图片验证码');
                                    }
                               } 
                            }
                        ]}
                    >
                    {this.state.isVisiblePhoneCode?
                     <Flex>
                        <InputItem
                            {...getFieldProps("validateKeyPhone",
                            {
                                rules: [
                                    {
                                        required: true,
                                        message:"必须输入"
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(value && value.length == 5) {
                                                api.user.validateImgCode({validateCode:value},
                                                    function (state,data) {
                                                        if(state && data.status == 200) {
                                                            callback()
                                                        } else {
                                                            callback('error');
                                                        }
                                                    }
                                                );
                                            } else {
                                                callback('error');
                                            }
                                        },
                                        message: '验证码输入错误',
                                    },
                                    ],
                            }
                            )}
                            error = {getFieldError('validateKeyPhone')}
                            placeholder="请输入图片验证码"
                        />
                        <img  
                          onClick={()=>this.refreshImage()}                       
                          style={{width: 120, height: 40}}
                          src={'/api/user/generateCodeImg?imgnum'+this.state.imgenum}/>
                        </Flex>:null}
                    </Modal>
                </WingBlank>
                </form>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.myUser.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveUserInfoToStore: (data) => dispatch({
            type: Action.SAVE_USER_INFO,
            data: data,
        }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(createForm()(Login));

