import React from 'react';
import {HashRouter,Route} from 'react-router-dom';

import Home from './Home';
import Login from "./user/Login";
import VideoPlay from './personal/VideoPlay';
import PlayRecord from './personal/PlayRecord';
import UserInfo from './user/userInfo/UserInfo';
import UserInfoSetting from './user/userInfo/UserInfoSetting';
import InfoClass from './user/userInfo/InfoClass';
import RoutePath from '../config/RoutePath';
import SeriesPub from './pub/SeriesPub';
import SeriesDetail from './pub/SeriesDetail';
import AnswerRecord from './personal/answer/AnswerRecord';
import MyClassRecord from './personal/myclass/MyClassRecord';
import MyTaskRecord from './personal/mytask/MyTaskRecord';
import MyMessageList from './personal/mymessage/MyMessageList';
import AnswerDetail from './personal/answer/AnswerDetail';
import TaskDetail from './personal/mytask/TaskDetail';
import RealNameAuth from './user/userInfo/RealNameAuth';
import GetMyCreatedTopicList from './personal/qa/GetMyCreatedTopicList';
import GetQaDetail from './personal/qa/GetQaDetail';
import ThirdQrReader from './qr/ThirdQrReader';
import FetchPersonalStatics from './personal/statistic/FetchPersonalStatics';
import Register from './user/Register';
import MyPaperRecord from './personal/writepaper/MyPaperRecord';
import NewsList from '../views/news/NewsList'
import NewsDetail from '../views/news/NewsDetail'
import NewsHistory from '../views/news/NewsHistory'
import {CertificateQuery} from './CertificateQuery'
const Root = () => (
        <HashRouter>
            <Route exact path="/" component={Home}/>
            <Route exact path={RoutePath.Home} component={Home}/>
            <Route exact path={RoutePath.Login} component={Login}/>
            <Route exact path={RoutePath.Register} component={Register}/>
            <Route exact path={RoutePath.VideoPlay+'/:vid'} component={VideoPlay}/>
            <Route exact path={RoutePath.UserInfo} component={UserInfo}/>
            <Route exact path={RoutePath.UserInfoSetting+'/:classify'} component={UserInfoSetting}/>
            <Route exact path={RoutePath.PlayRecord} component={PlayRecord}/>
            <Route exact path={RoutePath.InfoClass} component={InfoClass}/>
            <Route exact path={RoutePath.SeriesPub} component={SeriesPub}/>
            <Route exact path={RoutePath.SeriesDetail+'/:seriesId'} component={SeriesDetail}/>
            <Route exact path={RoutePath.AnswerRecord} component={AnswerRecord}/>
            <Route exact path={RoutePath.MyClassRecord} component={MyClassRecord}/>
            <Route exact path={RoutePath.MyTaskRecord+'/:taskType'} component={MyTaskRecord}/>
            <Route exact path={RoutePath.MyMessageList} component={MyMessageList}/>
            <Route exact path={RoutePath.AnswerDetail + '/:paperId'} component={AnswerDetail}/>
            <Route exact path={RoutePath.TaskDetail+"/:mtaskId"} component={TaskDetail}/>
            <Route exact path={RoutePath.RealNameAuth} component={RealNameAuth}/>
            <Route exact path={RoutePath.GetMyCreatedTopicList} component={GetMyCreatedTopicList}/>
            <Route exact path={RoutePath.GetQaDetail+"/:topicId"} component={GetQaDetail}/>
            <Route exact path={RoutePath.ThirdQrReader} component={ThirdQrReader} />
            <Route exact path={RoutePath.FetchPersonalStatics} component={FetchPersonalStatics} />
            <Route exact path={RoutePath.MyPaperRecord} component={MyPaperRecord}/>
            <Route exact path={RoutePath.NewsList} component={NewsList}/>
            <Route exact path={RoutePath.NewsDetail + '/:newsId'} component={NewsDetail}/>
            <Route exact path={RoutePath.NewsHistory} component={NewsHistory}/>
            <Route exact path={RoutePath.CertificateQuery} component={CertificateQuery}/>
        </HashRouter>
);

export default Root;





