import React,{ Component } from "react";
import {connect } from 'react-redux';
import {List, Button, WhiteSpace, TextareaItem, Toast, Modal} from "antd-mobile";
import { createForm } from 'rc-form';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";

const Item = List.Item;
const Brief = Item.Brief;
const alert = Modal.alert;

class GetQaDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            qaDetail:null,
        };
    }

    getQaDetailByTopicId() {
        let topicId = this.props.match.params.topicId;
        api.qa.getQaDetailByTopicId({topicId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        qaDetail:data.data,
                    });
                }
            }
        )
    }

    onSubmit = e => {
        e.preventDefault();
        let topicId = this.props.match.params.topicId;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.qa.addFollow(
                    {topicId, ...values},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.getQaDetailByTopicId();
                            this.props.form.resetFields();
                        } else {
                            Toast.fail(data.msg);
                        }
                    }
                )
            }
        });
    };

    componentDidMount() {
        this.getQaDetailByTopicId();
    }

    render() {
        let {qaDetail} = this.state;
        const { getFieldProps,getFieldError} = this.props.form;

        return (
            <MyLayout title="问题回复">
                <div>
                    <div className="article-info-box">
                        <div className="title-article">
                            <strong>{qaDetail ? qaDetail.content:""}</strong>
                        </div>
                        <div className="article-bar-top">
                            <span >{qaDetail ? qaDetail.createdTime:""}</span>
                        </div>
                    </div>
                    {
                        qaDetail && qaDetail.qaFollows?
                            qaDetail.qaFollows.map((data, index) => {
                                return (
                                    <div className="article-content" key={index}>
                                        <div className="img-avatar-box">
                                            <img 
                                                 alt=""
                                                 style={{borderRadius: "50%"}}
                                                 src={data.userName === qaDetail.authorName?
                                                     require('../../../assets/images/ask.png'):
                                                     require('../../../assets/images/answer.png')}
                                                 width="32" height="32"/>
                                        </div>
                                        <div className="answer">
                                            <span className="name">{data.userName}</span>
                                            <span className="time">{data.createdTime}</span>
                                        </div>
                                        <div className="answer-content">
                                            <span>{data.content}</span>
                                        </div>
                                    </div>
                                )
                            }):""
                    }
                </div>
                <List>
                    <TextareaItem
                        {...getFieldProps('content', {
                            rules: [
                                {
                                    required: true,
                                    validateStatus:'error',
                                    message: '问题是必须输入的!'
                                },
                            ],
                        })}
                        placeholder="问题是必须输入的!"
                        rows={3}
                        count={1000}
                        error = {getFieldError('content')}
                    />
                    <List.Item>
                        <Button type="ghost" onClick={this.onSubmit}>提交</Button>
                    </List.Item>
                </List>
            </MyLayout>
        );
    }
}
export default createForm()(GetQaDetail);